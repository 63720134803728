import type { LoggerOptions } from "pino";
import { pino } from "pino";

export type IChildLoggerOptions = pino.ChildLoggerOptions;
export type IBindings = pino.Bindings;
export type ILogger = pino.Logger;
export type IStream = pino.DestinationStream;

Error.stackTraceLimit = Infinity;

export function createLogger(bindings: IBindings, opts: IChildLoggerOptions): ILogger {
  const isDev =
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test" ||
    process.env.VITE_DISABLE_EXTERNAL_LOGGING === "true";

  const pinoConf: LoggerOptions = {
    level: isDev ? "trace" : "info",
    browser: {
      asObject: true,
    },
  };

  const pinoTransport: IStream = process.stdout;

  const parentLogger = pino(pinoConf, pinoTransport);

  return parentLogger.child({ ...bindings, env: process.env.NODE_ENV }, opts);
}

export const createModuleLogger = (
  bindings: IBindings & { module: string },
  moduleOpts?: IChildLoggerOptions,
): {
  moduleLogger: ILogger;
  createFileLogger: (file: string, fileOpts?: IChildLoggerOptions) => ILogger;
} => {
  const moduleLogger = createLogger(bindings, moduleOpts ?? {});

  return {
    moduleLogger,
    createFileLogger: (file, fileOpts) => moduleLogger.child({ file }, fileOpts ?? {}),
  };
};
