import { fromPairs } from "./fromPairs";
import { toPairs } from "./toPairs";

/**
 * Asynchronously maps the values of an object.
 */
export function mapValuesAsync<const Key extends string, const OldValue, const NewValue>(
  obj: Record<Key, OldValue>,
  iteratee: (value: OldValue, key: Key) => Promise<NewValue>,
): Promise<Record<Key, NewValue>> {
  return Promise.all(
    (toPairs(obj) as [Key, OldValue][]).map(([key, value]) =>
      iteratee(value, key).then((newValue) => [key, newValue] as [Key, NewValue]),
    ),
  ).then(fromPairs);
}
