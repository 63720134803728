import * as React from "react";

import { cn } from "../../lib/utils";
import { Loader } from "../molecules/loader/Loader";

export const ComputingSuggestion: React.FC<{
  className?: string;
}> = ({ className }) => (
  <div className={cn("flex items-center space-x-1", className)}>
    <Loader text="Using our big brain..." variant="pyramid" />
  </div>
);
