import React from "react";

import { cn } from "../../lib/utils";

export const Spinner: React.FC<{ className?: string }> = ({ className }) => (
  <span className={cn("inline-block size-4 text-current", className)} role="progressbar">
    <svg viewBox="0 0 100 100">
      <path
        d="M 50 20 L 80 70 L 20 70 Z"
        fill="none"
        stroke="currentColor"
        strokeDasharray="30"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
      >
        <animate attributeName="stroke-dashoffset" dur="3s" from="180" repeatCount="indefinite" to="0" />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="3s"
          from="0 50 53"
          repeatCount="indefinite"
          to="360 50 53"
          type="rotate"
        />
      </path>
    </svg>
  </span>
);
