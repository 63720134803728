import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useCallback } from "react";

import { cn } from "../../lib/utils";

type ITruncateRenderer = (state: { hiddenItemsCount: number }) => React.ReactNode;

export type ITruncateList = {
  renderTruncator: ITruncateRenderer;
  children?: React.ReactNode;
  alwaysShowTruncator?: boolean;
  showTruncator?: boolean;
  className?: string;
  style?: React.CSSProperties;
  truncatePosition?: "start" | "middle" | "end";
};

const rectContainsRect = (parent: DOMRect, child: DOMRect): boolean => {
  return (
    child.top >= parent.top && child.bottom <= parent.bottom && child.left >= parent.left && child.right <= parent.right
  );
};

export const TruncateList: React.FC<ITruncateList> = ({
  renderTruncator,
  alwaysShowTruncator,
  showTruncator = true,
  children,
  className,
  style,
  truncatePosition = "end",
}) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const [hiddenCount, setHiddenCount] = useState(0);

  const truncate = useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    const childNodes = Array.from(containerRef.current.children) as HTMLElement[];

    // If there's only one item, truncate the text instead of the list
    if (childNodes.length === 3) {
      // 1 item + 2 truncators
      const itemEl = childNodes[1];

      if (itemEl) {
        itemEl.style.overflow = "hidden";
        itemEl.style.textOverflow = "ellipsis";
        itemEl.style.whiteSpace = "nowrap";
        itemEl.style.maxWidth = "100%";
      }

      return;
    }

    // Put the list in its initial state
    containerRef.current.style.overflow = "hidden";
    // Show all items, hide all truncators
    for (let i = 0; i < childNodes.length; ++i) {
      const targetItem = childNodes[i];

      if (targetItem) {
        targetItem.hidden = i % 2 === 0;
      }
    }

    // Test if truncation is necessary
    if (alwaysShowTruncator === true) {
      const truncatorEl = childNodes[childNodes.length - 1];

      if (truncatorEl) {
        truncatorEl.hidden = false;
        if (rectContainsRect(containerRef.current.getBoundingClientRect(), truncatorEl.getBoundingClientRect())) {
          return;
        }
        truncatorEl.hidden = true;
      }
    } else {
      const itemEl = childNodes[childNodes.length - 2];

      if (itemEl && rectContainsRect(containerRef.current.getBoundingClientRect(), itemEl.getBoundingClientRect())) {
        return;
      }
    }

    if (truncatePosition === "start") {
      // Start truncation logic - show last 2 items
      const lastItem = childNodes[childNodes.length - 2];
      const secondLastItem = childNodes[childNodes.length - 4];

      // First hide all items
      for (let i = 1; i < childNodes.length - 4; i += 2) {
        const itemEl = childNodes[i];

        if (itemEl) {
          itemEl.hidden = true;
        }
      }

      // Then show just the last two items
      if (lastItem) lastItem.hidden = false;
      if (secondLastItem) secondLastItem.hidden = false;

      // Hide all truncators except first one if showTruncator is true
      for (let i = 0; i < childNodes.length; i += 2) {
        const truncatorEl = childNodes[i];

        if (truncatorEl) {
          truncatorEl.hidden = !showTruncator || i !== 0;
        }
      }

      // Update hidden count state - fix the calculation to count actual hidden items
      const actualHiddenCount = Math.floor((childNodes.length - 4) / 2); // -4 because we show 2 items and have 2 truncators

      setHiddenCount(actualHiddenCount);

      return;
    }

    if (truncatePosition === "middle") {
      // For middle truncation, always show first and last items
      // Hide all items except first and last
      for (let i = 1; i < childNodes.length - 1; i += 2) {
        const itemEl = childNodes[i];

        if (itemEl) {
          itemEl.hidden = i !== 1 && i !== childNodes.length - 2;
        }
      }

      // Hide all truncators if showTruncator is false, otherwise show middle truncator
      for (let i = 0; i < childNodes.length; i += 2) {
        const truncatorEl = childNodes[i];

        if (truncatorEl) {
          const middleIndex = Math.floor((childNodes.length - 1) / 4) * 2;

          truncatorEl.hidden = !showTruncator || i !== middleIndex;
        }
      }

      return;
    }

    // End truncation logic
    if (!showTruncator) {
      // When not showing truncator, show first 2 items
      for (let i = 1; i < childNodes.length - 1; i += 2) {
        const itemEl = childNodes[i];

        if (itemEl) {
          itemEl.hidden = i > 3; // Show first 2 items (indices 1, 3)
        }
      }
      // Hide all truncators
      for (let i = 0; i < childNodes.length; i += 2) {
        const truncatorEl = childNodes[i];

        if (truncatorEl) {
          truncatorEl.hidden = true;
        }
      }

      return;
    }

    const numTruncators = Math.floor((childNodes.length - 1) / 2);
    let left = 0;
    let right = numTruncators - 1;
    let truncatorIndex: number | null = null;

    while (left <= right) {
      const middle = Math.floor((left + right) / 2);

      // show all items before the truncator
      for (let i = 0; i < middle; i += 1) {
        const itemEl = childNodes[i * 2 + 1];

        if (itemEl) {
          itemEl.hidden = false;
        }
      }
      // hide all items after the truncator
      for (let i = middle; i < numTruncators; i += 1) {
        const itemEl = childNodes[i * 2 + 1];

        if (itemEl) {
          itemEl.hidden = true;
        }
      }

      const truncatorEl = childNodes[middle * 2];

      if (truncatorEl) {
        truncatorEl.hidden = false;

        if (rectContainsRect(containerRef.current.getBoundingClientRect(), truncatorEl.getBoundingClientRect())) {
          truncatorIndex = middle;
          left = middle + 1;
        } else {
          right = middle - 1;
        }

        truncatorEl.hidden = true;
      }
    }

    if (truncatorIndex === null) {
      return;
    }

    // Show all items before the truncator
    for (let i = 0; i < truncatorIndex; i += 1) {
      const itemEl = childNodes[i * 2 + 1];

      if (itemEl) {
        itemEl.hidden = false;
      }
    }
    // Hide all items after truncator
    for (let i = truncatorIndex; i < numTruncators; i += 1) {
      const itemEl = childNodes[i * 2 + 1];

      if (itemEl) {
        itemEl.hidden = true;
      }
    }
    const truncatorEl = childNodes[truncatorIndex * 2];

    if (truncatorEl) {
      truncatorEl.hidden = !showTruncator;
    }
  }, [alwaysShowTruncator, truncatePosition, showTruncator]);

  // Set up a resize observer
  useLayoutEffect(() => {
    const el = containerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const _ of entries) {
        truncate();
      }
    });

    if (el) {
      resizeObserver.observe(el);
    }

    return (): void => {
      if (el) {
        resizeObserver.unobserve(el);
      }
    };
  }, [truncate]);

  const childArray = React.Children.toArray(children);

  const items = useMemo(() => {
    return childArray.map((item, i) => {
      const itemKey = React.isValidElement(item) ? item.key : null;
      const key = itemKey !== null ? String(itemKey) : `item-${i.toString()}`;
      const count = truncatePosition === "start" ? hiddenCount : childArray.length - i;

      return (
        <React.Fragment key={key}>
          <li hidden>{renderTruncator({ hiddenItemsCount: count })}</li>
          <li className="whitespace-nowrap">{item}</li>
        </React.Fragment>
      );
    });
  }, [childArray, renderTruncator, truncatePosition, hiddenCount]);

  return (
    <ul ref={containerRef} className={cn("flex items-center gap-x-1 overflow-hidden", className)} style={style}>
      {items}
      <li hidden className="whitespace-nowrap">
        {renderTruncator({ hiddenItemsCount: 0 })}
      </li>
    </ul>
  );
};
