import { Slot } from "@radix-ui/react-slot";
import * as React from "react";

import { cn } from "../../lib/utils";
import { Icon } from "./icon";
import { TruncateList } from "./truncate-list";

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav"> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" className="flex items-center" {...props} />);

Breadcrumb.displayName = "Breadcrumb";

const BreadcrumbList = React.forwardRef<HTMLOListElement, React.ComponentPropsWithoutRef<"ol">>(
  ({ className, children }, _ref) => {
    const renderTruncator = React.useCallback(
      ({ hiddenItemsCount }: { hiddenItemsCount: number }) => (
        <div className="flex items-center gap-1.5">
          <BreadcrumbEllipsis>
            <span className="sr-only">{hiddenItemsCount} more breadcrumb items</span>
          </BreadcrumbEllipsis>
          <BreadcrumbSeparator />
        </div>
      ),
      [],
    );

    const childArray = React.Children.toArray(children);

    return (
      <TruncateList
        className={cn("flex min-w-0 items-center gap-2 text-base text-muted-foreground", className)}
        renderTruncator={renderTruncator}
        showTruncator={false}
        truncatePosition="middle"
      >
        {childArray.map((child, index) => {
          const isLast = index === childArray.length - 1;
          const elementKey = React.isValidElement(child) ? child.key : null;
          const itemKey = elementKey !== null ? String(elementKey) : `breadcrumb-${index.toString()}`;

          return (
            <div key={itemKey} className="flex min-w-0 items-center gap-2">
              <div className="min-w-0 flex-1">{child}</div>
              {!isLast && <BreadcrumbSeparator className="flex-none" />}
            </div>
          );
        })}
      </TruncateList>
    );
  },
);

BreadcrumbList.displayName = "BreadcrumbList";

const BreadcrumbItem = React.forwardRef<HTMLLIElement, React.ComponentPropsWithoutRef<"li">>(
  ({ className, children, ...props }, ref) => (
    <li ref={ref} className={cn("min-w-0 shrink", className)} {...props}>
      {children}
    </li>
  ),
);

BreadcrumbItem.displayName = "BreadcrumbItem";

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a"> & {
    asChild?: boolean;
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = (asChild ?? false) ? Slot : "a";

  return (
    <Comp
      ref={ref}
      className={cn(
        "block min-w-0 truncate transition-colors hover:text-ink focus-visible:text-ink focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring",
        className,
      )}
      {...props}
    />
  );
});

BreadcrumbLink.displayName = "BreadcrumbLink";

const BreadcrumbPage = React.forwardRef<HTMLSpanElement, React.ComponentPropsWithoutRef<"span">>(
  ({ className, ...props }, ref) => (
    <span
      ref={ref}
      aria-current="page"
      aria-disabled="true"
      className={cn("block min-w-0 truncate text-base text-ink", className)}
      {...props}
    />
  ),
);

BreadcrumbPage.displayName = "BreadcrumbPage";

const BreadcrumbSeparator = ({ children, className, ...props }: React.ComponentProps<"li">): React.ReactElement => (
  <li
    aria-hidden="true"
    className={cn("flex items-center text-muted-foreground/50 [&>svg]:size-3", className)}
    role="presentation"
    {...props}
  >
    {children ?? <Icon name="chevron-right" />}
  </li>
);

BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

const BreadcrumbEllipsis = ({ className, ...props }: React.ComponentProps<"span">): React.ReactElement => (
  <span
    aria-hidden="true"
    className={cn("flex size-6 items-center justify-center text-muted-foreground/70", className)}
    role="presentation"
    {...props}
  >
    <Icon className="size-3" name="more-horizontal" />
    <span className="sr-only">More</span>
  </span>
);

BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";

export {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
};
