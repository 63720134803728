"use client";

import * as PortalPrimitive from "@radix-ui/react-portal";
import * as React from "react";

interface IPortalProps {
  children: React.ReactNode;
}

export const Portal = ({ children }: IPortalProps): React.JSX.Element => {
  return <PortalPrimitive.Root>{children}</PortalPrimitive.Root>;
};

Portal.displayName = "Portal";
