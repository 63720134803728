"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

import { cn } from "../../lib/utils";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & { small?: boolean; readOnly?: boolean }
>(({ className, small = false, readOnly = false, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer inline-flex shrink-0 cursor-pointer items-center rounded-lg border border-primary transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-paper disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-muted hover:data-[state=checked]:bg-primary/90 hover:data-[state=unchecked]:bg-secondary-background",
      small ? "h-[18px] w-[31px] rounded-sm" : "h-[26px] w-[45px]",
      className,
    )}
    {...props}
    ref={ref}
    disabled={readOnly}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block rounded-lg bg-paper shadow-[inset_0_-4px_hsl(var(--secondary-bg))] outline outline-1 outline-primary transition-transform",
        small
          ? "size-4 rounded-sm shadow-[inset_0_-2px_hsl(var(--secondary-bg))] data-[state=checked]:translate-x-3.5 data-[state=unchecked]:-translate-x-px"
          : "size-6 data-[state=checked]:translate-x-5 data-[state=unchecked]:-translate-x-px",
      )}
    />
  </SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
