import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

// Variants need to be separated because they are applied to different elements and we dont want defaults
const dotPingingColorVariants = cva("", {
  variants: {
    color: {
      primary: "bg-primary",
      purple: "bg-purple-600",
      gray: "bg-muted-foreground",
      default: "bg-muted-foreground",
      danger: "bg-red-500",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

const dotPingingSizeVariants = cva("", {
  variants: {
    size: {
      xs: "size-1.5",
      sm: "size-1.5",
      md: "size-2",
      lg: "size-2",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const DotPinging: React.FC<
  { className?: string } & VariantProps<typeof dotPingingColorVariants> & VariantProps<typeof dotPingingSizeVariants>
> = ({ className, color, size }) => (
  <span className={cn(dotPingingSizeVariants({ size }), "relative flex", className)}>
    <span
      className={cn(
        dotPingingColorVariants({ color }),
        "absolute inline-flex size-full animate-ping rounded-full opacity-75",
      )}
    />
    <span
      className={cn(
        dotPingingColorVariants({ color }),
        dotPingingSizeVariants({ size }),
        "relative inline-flex rounded-full",
      )}
    />
  </span>
);

export default DotPinging;
DotPinging.displayName = "DotPinging";

export { DotPinging };
