import { isEqual } from "lodash";
import type React from "react";
import { useMemo, useRef } from "react";

export function useDeepCompareMemoize(dependencies: React.DependencyList): React.DependencyList {
  const dependenciesRef = useRef<React.DependencyList>(dependencies);
  const signalRef = useRef<number>(0);

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps -- safe with logic
  return useMemo(() => dependenciesRef.current, [signalRef.current]);
}
