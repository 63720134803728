import { cn } from "../../lib/utils";

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>): React.JSX.Element {
  return (
    <div
      className={cn(
        "pointer-events-none relative inline-block select-none overflow-hidden rounded-md bg-skeleton p-0 text-transparent before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-paper/25 before:to-transparent",
        className,
      )}
      {...props}
    />
  );
}

export { Skeleton };
