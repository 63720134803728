import { ScrollArea } from "@radix-ui/react-scroll-area";
import React from "react";

import { cn } from "../../lib/utils";
import { EntityStateColor } from "./entity-state-color-indicator";
import { ScrollAreaProvider } from "./scroll-area/scroll-area-provider";
import { Skeleton } from "./skeleton";
import type { ITableSkeleton } from "./table-skeleton";
import { TableSkeleton } from "./table-skeleton";

const fakeGroups = [
  {
    groupValue: "—————————",
    title: "—————————",
    numberRows: 4,
    numberCols: 12,
  },
  {
    groupValue: "—————————————————————",
    title: "—————————————————————",
    numberRows: 2,
    numberCols: 12,
  },
  {
    groupValue: "———————",
    title: "———————",
    numberRows: 6,
    numberCols: 12,
  },
];

interface IGroupTableSkeleton {
  className?: string;
  density?: ITableSkeleton["density"];
}

export const GroupTableSkeleton: React.FC<IGroupTableSkeleton> = ({ className, density }) => {
  return (
    <ScrollAreaProvider direction="vertical">
      <ScrollArea className={cn("flex h-full flex-col space-y-6", className)}>
        {fakeGroups.map(({ numberRows, numberCols, groupValue }) => {
          return (
            <div key={groupValue} className="shrink-0">
              <div className="mb-4 flex min-w-0 items-center gap-x-2">
                <EntityStateColor color="neutral" />
                <Skeleton className="truncate">{groupValue}</Skeleton>
              </div>
              <TableSkeleton density={density ?? "default"} numberCols={numberCols} numberRows={numberRows} />
            </div>
          );
        })}
      </ScrollArea>
    </ScrollAreaProvider>
  );
};
