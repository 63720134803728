"use client";

import type { Dispatch, SetStateAction } from "react";
import { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import type { DropzoneOptions, DropzoneState, FileRejection } from "react-dropzone";
export type { FileRejection };
import { useDropzone } from "react-dropzone";

import { cn } from "../../lib/utils";
import { createFileLogger } from "../../logger";
import { buttonVariants } from "./button";
import { Icon } from "./icon";
import { Input } from "./input";
import { toast } from "./use-toast";

type IDirectionOptions = "rtl" | "ltr" | undefined;

type IFileUploaderContextType = {
  dropzoneState: DropzoneState;
  isLOF: boolean;
  onFileRemoval: (index: number) => Promise<void>;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  orientation: "horizontal" | "vertical";
  direction: IDirectionOptions;
};

const FileUploaderContext = createContext<IFileUploaderContextType | null>(null);

const logger = createFileLogger("FileUploader");

export const useFileUpload = (): IFileUploaderContextType => {
  const context = useContext(FileUploaderContext);

  if (!context) {
    throw new Error("useFileUpload must be used within a FileUploaderProvider");
  }

  return context;
};

type IFileUploaderProps = {
  value: File[] | null;
  onNewFiles: (value: File[] | null) => Promise<void>;
  onFileRemoval: (i: number) => Promise<void>;
  dropzoneOptions: DropzoneOptions;
  orientation?: "horizontal" | "vertical";
};

/**
 * File upload Docs: {@link: https://localhost:3000/docs/file-upload}
 */

export const FileUploader = forwardRef<HTMLDivElement, IFileUploaderProps & React.HTMLAttributes<HTMLDivElement>>(
  (
    { className, dropzoneOptions, value, onNewFiles, onFileRemoval, orientation = "vertical", children, dir, ...props },
    ref,
  ): React.JSX.Element => {
    const [isLOF, setIsLOF] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const { maxFiles = 1, maxSize = 4 * 1024 * 1024 } = dropzoneOptions;

    const direction: IDirectionOptions = dir === "rtl" ? "rtl" : "ltr";

    const onDrop = useCallback(
      async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const files = acceptedFiles;

        if (files.length === 0) {
          return;
        }

        await onNewFiles(files);

        if (rejectedFiles.length > 0) {
          for (let i = 0; i < rejectedFiles.length; i++) {
            const rejectedFile = rejectedFiles[i];

            if (rejectedFile) {
              const firstError = rejectedFile.errors[0];
              const errorMessage =
                firstError?.code === "file-too-large"
                  ? `File is too large. Max size is ${(maxSize / 1024 / 1024).toFixed()}MB`
                  : firstError?.message;

              toast({
                title: errorMessage,
              });
              break;
            }
          }
        }
      },
      [onNewFiles, maxSize],
    );

    useEffect(() => {
      if (!value) return;
      if (value.length === maxFiles) {
        setIsLOF(true);

        return;
      }
      setIsLOF(false);
    }, [value, maxFiles]);

    const opts = dropzoneOptions;

    const dropzoneState = useDropzone({
      ...opts,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- functional
      onDrop,
      // needed for e2e tests - https://github.com/react-dropzone/react-dropzone/discussions/1339
      useFsAccessApi: false,
    });

    return (
      <FileUploaderContext.Provider
        value={{
          dropzoneState,
          isLOF,
          onFileRemoval,
          activeIndex,
          setActiveIndex,
          orientation,
          direction,
        }}
      >
        <div
          ref={ref}
          className={cn("grid w-full overflow-hidden focus:outline-none ", className, {
            "gap-2": value && value.length > 0,
          })}
          dir={dir}
          tabIndex={0}
          {...props}
        >
          {children}
        </div>
      </FileUploaderContext.Provider>
    );
  },
);

FileUploader.displayName = "FileUploader";

export const FileUploaderContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {
    const { orientation } = useFileUpload();
    const containerRef = useRef<HTMLDivElement>(null);

    return (
      <div ref={containerRef} aria-description="content file holder" className={cn("w-full py-1")}>
        <div
          {...props}
          ref={ref}
          className={cn("flex gap-1 rounded-xl", orientation === "horizontal" ? "flex-wrap" : "flex-col", className)}
        >
          {children}
        </div>
      </div>
    );
  },
);

FileUploaderContent.displayName = "FileUploaderContent";

export const FileUploaderItem = forwardRef<
  HTMLDivElement,
  { index: number; showDeleteButton?: boolean } & React.HTMLAttributes<HTMLDivElement>
>(({ className, index, children, showDeleteButton = true, ...props }, ref) => {
  const { onFileRemoval, activeIndex, direction } = useFileUpload();
  const isSelected = index === activeIndex;

  const handleDelete = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await onFileRemoval(index);
      } catch (error) {
        logger.error("Error removing file", { error });
        toast({
          title: "Error removing file",
        });
      }
    },
    [onFileRemoval, index],
  );

  return (
    <div
      ref={ref}
      className={cn(
        buttonVariants({ variant: "ghost" }),
        "group relative h-6 cursor-pointer justify-between p-1 normal-case",
        className,
        isSelected ? "bg-accent-background" : "",
        "hover:bg-muted-background",
      )}
      {...props}
    >
      <div className="flex size-full items-center gap-1.5 text-sm leading-none tracking-tight text-ink">{children}</div>
      {showDeleteButton ? (
        <button
          className={cn(
            "absolute text-ink opacity-0 transition-opacity duration-200 group-hover:opacity-100",
            direction === "rtl" ? "left-1 top-1" : "right-1 top-1",
          )}
          type="button"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- functional
          onClick={handleDelete}
        >
          <span className="sr-only">remove item {index}</span>
          <Icon className="pointer-events-none size-3 duration-200 hover:stroke-error" name="trash-2" />
        </button>
      ) : null}
    </div>
  );
});

FileUploaderItem.displayName = "FileUploaderItem";

export const FileInput = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    const { dropzoneState, isLOF } = useFileUpload();
    const rootProps = isLOF ? {} : dropzoneState.getRootProps();

    return (
      <div
        ref={ref}
        {...props}
        className={`relative w-full ${isLOF ? "cursor-not-allowed opacity-50 " : "cursor-pointer "}`}
      >
        <div
          className={cn(
            "w-full rounded-lg border-border duration-300",
            dropzoneState.isDragAccept && "border-green-500",
            dropzoneState.isDragReject && "border-red-500",
            className,
          )}
          {...rootProps}
        >
          {children}
        </div>
        <Input
          ref={dropzoneState.inputRef}
          disabled={isLOF}
          {...dropzoneState.getInputProps()}
          className={isLOF ? "cursor-not-allowed" : ""}
        />
      </div>
    );
  },
);

FileInput.displayName = "FileInput";
