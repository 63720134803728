"use client";

import type { HTMLInputTypeAttribute, RefObject } from "react";
import React, { useCallback } from "react";
import type { ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../atoms/form";
import { Input } from "../../atoms/input";
import { Textarea } from "../../atoms/textarea";

export interface IFormInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, "render"> {
  inputRef?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>;
  type?: HTMLInputTypeAttribute | "textarea";
  label?: React.ReactNode;
  labelRight?: React.ReactNode;
  description?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  rows?: number;
  small?: boolean;
}

export function FormInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  inputRef,
  label,
  labelRight,
  type,
  description,
  placeholder,
  disabled,
  className,
  onKeyDown,
  onFocus,
  onBlur,
  rows,
  small,
  ...props
}: IFormInput<TFieldValues, TName>): React.JSX.Element {
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onKeyDown != null) {
        onKeyDown(e);
      }
    },
    [onKeyDown],
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onFocus != null) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onBlur != null) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  const renderInput = useCallback(
    ({ field }: { field: ControllerRenderProps<TFieldValues, TName> }): React.JSX.Element => {
      return (
        <FormItem>
          {label != null && (
            <div className="flex items-center justify-between">
              <FormLabel>{label}</FormLabel>
              {labelRight}
            </div>
          )}
          <FormControl>
            {type === "textarea" ? (
              <Textarea
                {...field}
                ref={inputRef as RefObject<HTMLTextAreaElement>}
                className={className}
                disabled={disabled}
                placeholder={placeholder}
                rows={rows}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
              />
            ) : (
              <Input
                {...field}
                ref={inputRef as RefObject<HTMLInputElement>}
                className={className}
                disabled={disabled}
                placeholder={placeholder}
                small={small}
                type={type}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
              />
            )}
          </FormControl>
          {description != null && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      );
    },
    [
      inputRef,
      label,
      labelRight,
      type,
      description,
      placeholder,
      disabled,
      className,
      handleKeyDown,
      handleFocus,
      handleBlur,
      rows,
      small,
    ],
  );

  return <FormField {...props} render={renderInput} />;
}
