"use client";

import Link from "next/link";

import { cn } from "../../lib/utils";
import { Icon } from "./icon";
import { Toast, ToastAction, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "./toast";
import { useToast } from "./use-toast";

export function Toaster(): React.JSX.Element {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, icon, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className={cn("flex w-full items-center justify-between gap-x-2")}>
              <div className="flex grow items-center gap-x-2">
                {icon != null && <Icon className="shrink-0" name={icon} />}
                <div className="grow gap-y-1">
                  {title != null && <ToastTitle>{title}</ToastTitle>}
                  {description != null && <ToastDescription>{description}</ToastDescription>}
                </div>
              </div>
              {action != null && (
                <ToastAction altText={action.label}>
                  <Link href={action.route}>{action.label}</Link>
                </ToastAction>
              )}
              <ToastClose />
            </div>
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
