import Link from "next/link";
import type { PropsWithChildren } from "react";
import React from "react";

import type { ILinkRenderer, IRoute } from "./api";
import { cn } from "./utils";

export function simpleLinkRenderer<T extends IRoute>(
  hrefOrGenerator: T | (() => T),
  { className }: { className?: string } = {},
): ILinkRenderer {
  const getHref = typeof hrefOrGenerator === "function" ? hrefOrGenerator : (): T => hrefOrGenerator;

  const LinkRenderer: ILinkRenderer = (props: PropsWithChildren & { className?: string }) => (
    <Link className={cn(className, props.className)} href={getHref()} passHref={true}>
      {props.children}
    </Link>
  );

  return LinkRenderer;
}
