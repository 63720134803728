import { useUser } from "@clerk/nextjs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import type { PropsWithChildren } from "react";
import { useEffect } from "react";

/**
 * Adds global context to relevant monitoring services
 */
export const UserMonitoringProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (user == null) {
      return;
    }

    const allEmailAddresses = user.emailAddresses.map((email) => email.emailAddress).join(", ");
    const primaryEmail = user.emailAddresses[0]?.emailAddress;

    // Identify user in Sentry
    Sentry.setUser({
      ...Sentry.getCurrentScope().getUser(),
      email: primaryEmail,
      allEmailAddresses,
      id: user.id,
      username: user.username ?? undefined,
      name: user.fullName ?? undefined,
      firstName: user.firstName ?? undefined,
      lastName: user.lastName ?? undefined,
    });

    // Identify user in Datadog RUM
    datadogRum.setUser({
      ...datadogRum.getUser(),
      id: user.id,
      email: primaryEmail,
      allEmailAddresses,
      name: user.fullName ?? undefined,
      username: user.username ?? undefined,
    });

    // Identify user in PostHog
    posthog.identify(user.id, {
      email: primaryEmail,
      name: user.fullName ?? undefined,
      username: user.username ?? undefined,
      firstName: user.firstName ?? undefined,
      lastName: user.lastName ?? undefined,
      allEmailAddresses,
    });
  }, [user]);

  useEffect(() => {
    const orgId = router.query.orgId;

    // Set organization context in Sentry
    Sentry.setTags({
      organizationId: orgId?.toString() ?? undefined,
    });

    // Set organization context in Datadog RUM
    datadogRum.setGlobalContext({
      organizationId: orgId?.toString() ?? undefined,
    });

    // Set organization context in PostHog
    if (orgId != null) {
      posthog.group("organization", orgId.toString());
    }
  }, [router.query]);

  return children;
};
