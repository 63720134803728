import { useCallback } from "react";

import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Icon } from "./icon";
import { Input } from "./input";
interface ISearchInput {
  onSubmit?: (value: string) => void;
  onClear?: () => void;
  placeholder?: string;
  small?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const SearchInput: React.FC<ISearchInput> = ({
  onSubmit,
  onClear,
  placeholder = "Search...",
  small = false,
  value,
  onChange,
}) => {
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit?.(value);
    },
    [onSubmit, value],
  );

  const handleClear = useCallback(() => {
    onChange("");
    onClear?.();
  }, [onChange, onClear]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      onChange(newValue);
    },
    [onChange],
  );

  return (
    <div className="flex w-full items-center">
      <form autoFocus={true} className={cn("relative flex w-full items-center")} onSubmit={handleSubmit}>
        <Icon className={cn("absolute left-2 z-10 text-muted-foreground", small && "size-3")} name="search" />
        <Button
          className={cn("absolute right-2 z-10", value === "" && "hidden", small && "right-1")}
          iconLeft="x"
          size={small ? "xs" : "sm"}
          title="Clear search"
          type="button"
          variant="ghost"
          onClick={handleClear}
        />
        <Input
          className={cn("z-0 border-none pl-8", small && "pl-6")}
          placeholder={placeholder}
          small={small}
          value={value}
          onChange={handleChange}
        />
      </form>
    </div>
  );
};
