import Image from "next/image";
import React from "react";

import { cn } from "../../../lib/utils";
import { Spinner } from "../../atoms/spinner";
import spinnerGif from "./spinning-triangle.gif";

export interface ILoader {
  text?: string | null;
  className?: string;
  variant?: "triangle" | "pyramid";
  /**
   * default sm
   */
  size?: "xs" | "sm";
}

export const Loader: React.FC<ILoader> = ({ className, text = "Loading...", variant = "triangle", size = "sm" }) => (
  <div
    className={cn("flex items-center text-sm text-muted-foreground", size === "xs" ? "gap-x-1" : "gap-x-2", className)}
  >
    {variant === "pyramid" ? (
      <Image
        alt="Loading"
        height={size === "xs" ? 16 : 24}
        src={spinnerGif}
        unoptimized={true}
        width={size === "xs" ? 16 : 24}
      />
    ) : (
      <Spinner />
    )}
    <span>{text}</span>
  </div>
);
