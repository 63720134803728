"use client";

import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

export const eyebrowVariants = cva(
  "inline-flex h-5 w-fit select-none items-center rounded border px-1 pb-0.5 pt-[3px] align-middle text-xs font-medium uppercase leading-[normal] tracking-wide text-ink",
  {
    variants: {
      variant: {
        primary: "border-primary bg-primary text-white",
        secondary: "border-border bg-accent",
        transparent: "border-transparent",
        border: "border-border",
      },
    },
    defaultVariants: {
      variant: "transparent",
    },
  },
);

const Eyebrow = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement> & VariantProps<typeof eyebrowVariants>
>(({ className, variant, ...props }, ref) => (
  <span ref={ref} className={cn(eyebrowVariants({ variant }), className)} {...props} />
));

Eyebrow.displayName = "Eyebrow";

export { Eyebrow };
