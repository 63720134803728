import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";
import type { IBaseButtonProps, IButtonPropsWithChildren, IButtonPropsWithoutChildren } from "./button";
import { Button } from "./button";
import type { dotNotificationVariants } from "./dot-notification";
import DotNotification from "./dot-notification";

const buttonNotificationDotVariants = cva("absolute", {
  variants: {
    variant: {
      // Adding the button variants here to ensure an outline for dark looking buttons, and danger default on danger
      primary: "",
      secondary: "",
      ghost: "",
      link: "",
    },
    size: {
      xs: "-right-px top-px size-2",
      sm: "-right-px -top-px size-2.5",
      md: "-right-0.5 -top-0.5 size-3",
      lg: "-right-0.5 -top-0.5 size-3",
    },
  },
  compoundVariants: [
    {
      variant: "link",
      size: ["xs"],
      className: "-right-2.5",
    },
    {
      variant: "link",
      size: ["sm"],
      className: "-right-3",
    },
    {
      variant: "link",
      size: ["md"],
      className: "-right-4 top-0.5",
    },
    {
      variant: "link",
      size: ["lg"],
      className: "-right-4 top-1.5",
    },
    {
      variant: "ghost",
      size: ["xs"],
      className: "right-0",
    },
    {
      variant: "ghost",
      size: ["sm"],
      className: "right-0",
    },
    {
      variant: "ghost",
      size: ["md"],
      className: "right-0.5",
    },
    {
      variant: "ghost",
      size: ["lg"],
      className: "right-0.5",
    },
  ],
  defaultVariants: {
    variant: "secondary",
    size: "md",
  },
});

// Explicitly removing the className from the props so that it can be handled explicitly between container and button itself
// class intersection forces to do this and not Omit<IButtonProps, "className">
type IButtonPropsWithoutClassName = Omit<IBaseButtonProps, "className"> &
  (IButtonPropsWithoutChildren | IButtonPropsWithChildren);

export type IButtonWithNotificationProps = IButtonPropsWithoutClassName &
  VariantProps<typeof buttonNotificationDotVariants> &
  VariantProps<typeof dotNotificationVariants> & {
    // Explicit which ones classes should be on the container (e.g. sizing classes), and which ones on the button
    containerClassName?: string;
    buttonClassName?: string;
    showDot?: boolean;
  };

const ButtonWithNotification = React.forwardRef<HTMLButtonElement, IButtonWithNotificationProps>(
  ({ showDot, notificationKind, size, variant, containerClassName, buttonClassName, ...buttonProps }, ref) => (
    <div className={cn("relative w-fit", containerClassName)}>
      <Button {...buttonProps} ref={ref} className={cn(buttonClassName, "w-full")} size={size} variant={variant} />
      {showDot === true && (
        <DotNotification
          className={buttonNotificationDotVariants({ size, variant })}
          notificationKind={notificationKind}
        />
      )}
    </div>
  ),
);

export default ButtonWithNotification;
ButtonWithNotification.displayName = "ButtonWithNotification";

export { ButtonWithNotification };
