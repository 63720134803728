import { forEach } from "./forEach";

/**
 * Takes in a record and returns a record of records based on the groupBy criteria for values/keys
 *
 * @param obj The source object
 * @param iteratee Function that returns the new group key for each value/key, undefined will be ignored
 * @returns A new object with the groups
 */
// mapKey: (value: Value, key: InKey) => OutKeys | undefined,
export function groupByRecord<const InKeys extends string, const InValue, const OutKeys extends string>(
  obj: Record<InKeys, InValue>,
  /**
   * If returning undefined, the key-vale pair will not be added to the output object.
   */
  iteratee: (value: InValue, key: InKeys) => OutKeys | undefined,
  // Partials to make it safer with keys extending string
): Record<OutKeys, Record<InKeys, InValue>> {
  const out = {} as Record<OutKeys, Record<InKeys, InValue>>;

  forEach(obj, (value, key) => {
    const outGroup = iteratee(value, key);

    if (outGroup == null) {
      // skip undefined
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition --  actually it is necessary, that's because of the generic string extension
    if (out[outGroup] == null) {
      out[outGroup] = {} as Record<InKeys, InValue>;
    }

    out[outGroup][key] = value;
  });

  return out;
}
