import type { Transformer } from "@lexical/markdown";

import { AutoFocusPlugin, AutoLinkPlugin, HistoryPlugin, LinkPlugin, ListPlugin, MarkdownShortcutPlugin } from "../";

export function DefaultPlugins({ transformers }: { transformers: Transformer[] }): React.JSX.Element {
  return (
    <>
      <MarkdownShortcutPlugin transformers={transformers} />
      <AutoLinkPlugin />
      <LinkPlugin />
      <ListPlugin />
      <AutoFocusPlugin />
      <HistoryPlugin />
    </>
  );
}
