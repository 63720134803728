import { builderTrpc } from "@archetype/trpc-react";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { AppLoader } from "../components/AppLoader";

const Page: NextPage = () => {
  const router = useRouter();

  const {
    data: activeOrganizationData,
    isLoading,
    isError,
  } = builderTrpc.users.activeOrganizationFromClerk.useQuery({});

  useEffect(() => {
    if (activeOrganizationData == null) {
      return;
    }

    const { organization: activeOrganization } = activeOrganizationData;

    // This is where, we can redirect to an org picker
    if (activeOrganization == null) {
      void router.push({
        pathname: "/select-organization",
        query: {},
      });

      return;
    }

    void router.push({
      pathname: "/[orgId]/workspace/[versionType]",
      query: {
        orgId: activeOrganization.id,
        versionType: "prod",
      },
    });
  }, [activeOrganizationData, isLoading, isError, router]);

  return <AppLoader />;
};

export default Page;
