"use client";

import { format } from "date-fns";
import * as React from "react";
import { useCallback } from "react";

import { cn } from "../../lib/utils";
import { Calendar } from "./calendar";
import { Icon } from "./icon";
import { inputVariants } from "./input";
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from "./popover";

const safeFormat = (date: Parameters<typeof format>[0], fmt: Parameters<typeof format>[1]): string => {
  try {
    return format(date, fmt);
  } catch {
    return date as unknown as string;
  }
};

interface IDateInput {
  className?: string;
  value: Date | undefined;
  placeholder?: string;
  onChange: (date: Date | undefined) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  small?: boolean;
  variant?: "button" | "text";
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
  closeOnSelect?: boolean;
}

interface IDateDisplay extends Omit<IDateInput, "onChange" | "isOpen" | "onOpenChange" | "closeOnSelect"> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ref?: React.Ref<HTMLButtonElement | HTMLSpanElement>;
}

export const DateDisplay = React.forwardRef<HTMLButtonElement, IDateDisplay>(
  (
    { className, value, placeholder, onBlur, onFocus, small, variant = "button", disabled, onClick },
    ref,
  ): React.ReactElement => {
    const handleBlur = useCallback(() => onBlur?.(), [onBlur]);
    const handleFocus = useCallback(() => onFocus?.(), [onFocus]);
    const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => onClick?.(e), [onClick]);

    const content = (
      <>
        {variant === "button" && <Icon className="mr-3 size-4 shrink-0" name="calendar" />}
        <span className={cn("truncate", value ? "text-ink" : "text-placeholder")}>
          {value != null ? safeFormat(value, "PPP") : (placeholder ?? "Select a date...")}
        </span>
      </>
    );

    if (variant === "text") {
      return (
        <button
          ref={ref}
          className={cn("flex h-full items-center truncate", className, small === true ? "text-base" : "text-lg")}
          role="button"
          type="button"
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
        >
          {content}
        </button>
      );
    }

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={cn(
          inputVariants({ size: small === true ? "small" : "default" }),
          "flex items-center justify-start overflow-hidden",
          className,
        )}
        disabled={disabled}
        role="button"
        type="button"
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
      >
        {content}
      </button>
    );
  },
);

DateDisplay.displayName = "DateDisplay";

export const DateInput = React.forwardRef<HTMLButtonElement, IDateInput>(
  (
    {
      className,
      value,
      placeholder,
      onChange,
      onBlur,
      onFocus,
      small,
      variant = "button",
      isOpen,
      onOpenChange,
      disabled,
      closeOnSelect = true,
    },
    ref,
  ): React.ReactElement => {
    const handleDateSelect = useCallback(
      (date: Date | undefined) => {
        onChange(date);
        if (closeOnSelect) {
          onOpenChange?.(false);
        }
      },
      [onChange, closeOnSelect, onOpenChange],
    );

    const handleOpenChange = useCallback((open: boolean) => onOpenChange?.(open), [onOpenChange]);
    const handleBlur = useCallback(() => onBlur?.(), [onBlur]);
    const handleFocus = useCallback(() => onFocus?.(), [onFocus]);

    return (
      <Popover open={isOpen} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <DateDisplay
            ref={ref}
            className={cn("w-full", className)}
            disabled={disabled}
            placeholder={placeholder}
            small={small}
            value={value}
            variant={variant}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent align="start" className="w-auto p-0">
            <Calendar
              defaultMonth={value}
              initialFocus={true}
              mode="single"
              selected={value}
              onSelect={handleDateSelect}
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    );
  },
);

DateInput.displayName = "DateInput";
