import { z } from "zod";

export type ISemanticInputsRecord<
  SingleItemType extends z.AnyZodObject,
  MultiItemQueryType extends z.AnyZodObject,
> = z.ZodObject<{
  singleEntityDataInput: z.ZodNullable<SingleItemType>;
  multiEntityQueryDataInput: MultiItemQueryType;
  primarySingleEntityDataInput: SingleItemType;
  secondarySingleEntityDataInput: SingleItemType;
}>;

/**
 *
 * @param itemType the zod type to extend with
 * @returns a record type with the input semantic ids as keys, and as values constraints for the corresponding type, and the extended type
 */
export function SemanticInputsRecord<SingleItemType extends z.AnyZodObject, MultiItemQueryType extends z.AnyZodObject>(
  singleItemType: SingleItemType,
  _multiItemType: z.AnyZodObject,
  multiItemQueryType: MultiItemQueryType,
): ISemanticInputsRecord<SingleItemType, MultiItemQueryType> {
  return z.object({
    singleEntityDataInput: z.nullable(singleItemType),
    multiEntityQueryDataInput: multiItemQueryType,
    /**
     * For example those could be relevant for a component that provides of comparison view of 2 entities
     */
    primarySingleEntityDataInput: singleItemType,
    secondarySingleEntityDataInput: singleItemType,
    // NOTE: can add literals but not sure if even useful
  });
}

export type ISemanticOutputsRecord<
  SingleItemType extends z.AnyZodObject,
  MultiItemType extends z.AnyZodObject,
  MultiItemQueryType extends z.AnyZodObject,
  ActionSelectedType extends z.AnyZodObject,
> = z.ZodObject<{
  userSelectedSingleEntity: z.ZodNullable<SingleItemType>;
  userSelectedLinkedEntity: z.ZodNullable<SingleItemType>;
  userSelectedMultiEntity: MultiItemType;
  filteredMultiEntityQuery: MultiItemQueryType;
  actionSelected: ActionSelectedType;
}>;

/**
 *
 * @param itemType the zod type to extend with
 * @returns a record type with the input semantic ids as keys, and as values constraints for the corresponding type, and the extended type
 */

// eslint-disable-next-line max-params -- type parameters
export function SemanticOutputsRecord<
  SingleItemType extends z.AnyZodObject,
  MultiItemType extends z.AnyZodObject,
  MultiItemQueryType extends z.AnyZodObject,
  ActionSelectedType extends z.AnyZodObject,
>(
  singleItemType: SingleItemType,
  multiItemType: MultiItemType,
  multiItemQueryType: MultiItemQueryType,
  actionSelectedType: ActionSelectedType,
): ISemanticOutputsRecord<SingleItemType, MultiItemType, MultiItemQueryType, ActionSelectedType> {
  return z.object({
    userSelectedSingleEntity: z.nullable(singleItemType),
    userSelectedLinkedEntity: z.nullable(singleItemType),
    /**
     * [Exploration]
     * Not sure those should be differentiated, probably cleaner, and can allow to enforce a configuration on the component
     * that it outputs one or the other, e.g. that a table is single selection or multi selection, not both
     * maybe that's even 2 components to simplify checks and avoid depending on that configuration
     */
    userSelectedMultiEntity: multiItemType,
    filteredMultiEntityQuery: multiItemQueryType,
    actionSelected: actionSelectedType,
  });
}

/**
 * Readable id/tag that will be matched against for compatibility of components and slots.
 * i.e. slots declare what `semanticId`s matching components must have, and that allows to filter which components
 * have them and are compatible (in addition to other constraints)
 * See usage in `Component` and `Slot`
 *
 * An alternative implementation would be explicitly store which slot inputId (e.g. a uuid) maps to what component inputId,
 * We could also automatically generate those mappings so functionally/UX wise equivalent, it's somehow cleaner, but potentially confusing,
 * unnecessary for now and can easily be migrated to later.
 */
export const InputSemanticId = SemanticInputsRecord(z.object({}), z.object({}), z.object({})).keyof();

/**
 * Same as InputSemanticId for outputs
 */
export const OutputSemanticId = SemanticOutputsRecord(z.object({}), z.object({}), z.object({}), z.object({})).keyof();

export type IInputSemanticId = z.infer<typeof InputSemanticId>;
export type IOutputSemanticId = z.infer<typeof OutputSemanticId>;
