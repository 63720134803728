import React from "react";

import type { IShapeColor } from "../../lib/shapeColor";
import type { IShapeName } from "../../lib/shapeName";
import { cn } from "../../lib/utils";
import { ShapeColorVariants } from "./shape-color";
import shapes from "./shapes.json";

export type IShapeColorIconSize = "sm" | "md" | "lg" | "xl" | "small-input";

export interface IShapeColorIconProps {
  shape: IShapeName;
  color: IShapeColor;
  size: IShapeColorIconSize;
  className?: string;
}

export const ShapeColorIcon: React.FC<IShapeColorIconProps> = ({ className, shape, color, size }) => {
  const shapeData = shapes.find((s) => s.shape === shape);

  return (
    <div
      className={cn(
        "group relative flex shrink-0 select-none items-center justify-center overflow-hidden border border-border text-primary hover:after:absolute hover:after:inset-0 hover:after:size-full hover:after:border hover:after:border-transparent hover:after:bg-accent-background",
        size === "sm" && "size-4 rounded",
        size === "md" && "size-5 rounded-md",
        size === "lg" && "size-6 rounded-md",
        size === "xl" && "size-8 rounded-md",
        size === "small-input" && "size-7 rounded-md",
        ShapeColorVariants({ color }),
        className,
      )}
    >
      {shapeData?.svg != null && shapeData.svg !== "" && (
        <div className="size-full transition-all group-hover:animate-wiggle">
          <svg
            dangerouslySetInnerHTML={{ __html: shapeData.svg }}
            height="100%"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 64 64"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          />
        </div>
      )}
    </div>
  );
};
