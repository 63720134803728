/// <reference types="@welldone-software/why-did-you-render" />
import React from "react";

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-require-imports -- ok for instrumentation
  const whyDidYouRender = require("@welldone-software/why-did-you-render");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- ok for instrumentation
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    logOwnerReasons: true,
    collapseGroups: true,
    logOnDifferentValues: true,
  });
}
