import { useCallback, useState } from "react";

export type IScrollState = "start" | "middle" | "end";

export function useScrollState(direction: "horizontal" | "vertical" | "both"): {
  scrollState: IScrollState;
  setScrollState: (newState: IScrollState) => void;
  handleScroll: (e: React.UIEvent<HTMLElement>) => void;
} {
  const [scrollState, setScrollState] = useState<IScrollState>("start");
  const innerHandleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const target = e.target as HTMLElement;

      if (direction === "both") {
        const horizontalScrollDistance = target.scrollLeft;
        const horizontalScrollSize = target.scrollWidth;
        const horizontalOffsetSize = target.offsetWidth;
        const verticalScrollDistance = target.scrollTop;
        const verticalScrollSize = target.scrollHeight;
        const verticalOffsetSize = target.offsetHeight;

        const isAtStart = horizontalScrollDistance === 0 && verticalScrollDistance === 0;
        const isAtEnd =
          horizontalScrollSize - horizontalOffsetSize - horizontalScrollDistance < 1 &&
          verticalScrollSize - verticalOffsetSize - verticalScrollDistance < 1;

        if (isAtStart) {
          setScrollState("start");
        } else if (isAtEnd) {
          setScrollState("end");
        } else {
          setScrollState("middle");
        }

        return;
      }

      const scrollDistance = direction === "horizontal" ? target.scrollLeft : target.scrollTop;
      const scrollSize = direction === "horizontal" ? target.scrollWidth : target.scrollHeight;
      const offsetSize = direction === "horizontal" ? target.offsetWidth : target.offsetHeight;

      if (scrollDistance === 0) {
        setScrollState("start");
      } else if (scrollSize - offsetSize - scrollDistance < 1) {
        setScrollState("end");
      } else {
        setScrollState("middle");
      }
    },
    [direction],
  );

  return {
    scrollState,
    setScrollState,
    handleScroll: innerHandleScroll,
  };
}
