import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { TextFormatType } from "lexical";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { cn } from "../../../../lib/utils";
import { Icon } from "../../../atoms/icon";
import { Portal } from "../../../atoms/portal";

interface IToolbarButtonProps {
  active?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

function ToolbarButton({ active = false, children, onClick: handleClick }: IToolbarButtonProps): React.JSX.Element {
  return (
    <button
      className={`rounded-sm p-1 text-xs hover:bg-muted hover:text-muted-foreground ${
        active ? "bg-muted text-muted-foreground" : "text-muted-foreground"
      }`}
      type="button"
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

export function FloatingToolbarPlugin(): React.JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [selectionRect, setSelectionRect] = useState<DOMRect | null>(null);
  const [position, setPosition] = useState({ top: -10000, left: -10000 });

  const updatePosition = useCallback(() => {
    const selection = window.getSelection();
    const toolbar = toolbarRef.current;

    if (!toolbar || !selection || selection.rangeCount === 0 || selection.toString().length === 0) {
      setIsVisible(false);

      return;
    }

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    if (rect.width === 0 || rect.height === 0) {
      setIsVisible(false);

      return;
    }

    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate initial position (centered above the selection)
    const toolbarWidth = toolbar.offsetWidth;
    const toolbarHeight = toolbar.offsetHeight;

    let left = rect.left + (rect.width - toolbarWidth) / 2;
    let top = rect.top - toolbarHeight - 8;

    // Ensure the toolbar doesn't go off-screen
    // Check left boundary
    if (left < 8) {
      left = 8;
    }
    // Check right boundary
    if (left + toolbarWidth > viewportWidth - 8) {
      left = viewportWidth - toolbarWidth - 8;
    }
    // If toolbar would go above viewport, position it below selection
    if (top < 8) {
      top = rect.bottom + 8;
    }
    // If toolbar would go below viewport, force it above
    if (top + toolbarHeight > viewportHeight - 8) {
      top = rect.top - toolbarHeight - 8;
    }

    setPosition({
      top: top + window.scrollY,
      left: left + window.scrollX,
    });
    setIsVisible(true);
  }, []);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      updatePosition();
    });
  }, [selectionRect, updatePosition]);

  // Add resize and scroll listeners
  useEffect(() => {
    const handleUpdate = (): void => {
      requestAnimationFrame(() => {
        updatePosition();
      });
    };

    window.addEventListener("resize", handleUpdate);
    window.addEventListener("scroll", handleUpdate, true);

    return (): void => {
      window.removeEventListener("resize", handleUpdate);
      window.removeEventListener("scroll", handleUpdate, true);
    };
  }, [updatePosition]);

  const formatText = useCallback(
    (formatType: TextFormatType) => {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
    },
    [editor],
  );

  const handleBoldClick = useCallback(() => {
    formatText("bold");
  }, [formatText]);

  const handleItalicClick = useCallback(() => {
    formatText("italic");
  }, [formatText]);

  const handleOrderedListClick = useCallback(() => {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  }, [editor]);

  const handleUnorderedListClick = useCallback(() => {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  }, [editor]);

  const handleLinkClick = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          setIsVisible(false);
          setSelectionRect(null);

          return;
        }

        // Only show toolbar if text is selected
        if (selection.isCollapsed()) {
          setIsVisible(false);
          setSelectionRect(null);

          return;
        }

        // Update text format
        setIsBold(selection.hasFormat("bold"));
        setIsItalic(selection.hasFormat("italic"));

        // Update link format
        const nodes = selection.getNodes();
        const node = nodes[0];
        const parent = node?.getParent();

        setIsLink(parent ? $isLinkNode(parent) : false);

        // Get selection rectangle
        const domRange = window.getSelection()?.getRangeAt(0);
        const rect = domRange?.getBoundingClientRect();

        if (rect) {
          setSelectionRect(rect);
        }
      });
    });
  }, [editor]);

  return (
    <Portal>
      <div
        ref={toolbarRef}
        className={cn(
          "fixed z-[999] flex flex-wrap gap-1 rounded-md border border-border bg-paper p-1 shadow",
          isVisible ? "opacity-100" : "opacity-0",
        )}
        style={{
          top: position.top,
          left: position.left,
        }}
      >
        <ToolbarButton active={isBold} onClick={handleBoldClick}>
          <Icon name="bold" />
        </ToolbarButton>
        <ToolbarButton active={isItalic} onClick={handleItalicClick}>
          <Icon name="italic" />
        </ToolbarButton>
        <div className="h-6 w-px bg-border" />
        <ToolbarButton onClick={handleOrderedListClick}>
          <Icon name="list-ordered" />
        </ToolbarButton>
        <ToolbarButton onClick={handleUnorderedListClick}>
          <Icon name="list" />
        </ToolbarButton>
        <div className="h-6 w-px bg-border" />
        <ToolbarButton active={isLink} onClick={handleLinkClick}>
          <Icon name="link" />
        </ToolbarButton>
      </div>
    </Portal>
  );
}
