import Link from "next/link";

import type { IUntypedRoute } from "../../api/untyped-route";
import type { IShapeColor } from "../../lib/shapeColor";
import type { IShapeName } from "../../lib/shapeName";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import type { IIconNames } from "./icon";
import { ShapeColorIcon } from "./shape-color-icon";

interface IEmptyStateProps {
  className?: string;
  icon?: {
    type: "shape";
    value: IShapeName;
    color: IShapeColor;
  };
  title: string;
  subTitle?: string;
  action?: {
    icon?: IIconNames;
    label: string;
    route: IUntypedRoute;
  };
}

export function EmptyState({ className, icon, title, subTitle, action }: IEmptyStateProps): React.JSX.Element {
  return (
    <div className={cn("flex w-full flex-col items-center justify-center gap-4 p-8 text-center", className)}>
      {icon != null && <ShapeColorIcon color={icon.color} shape={icon.value} size="xl" />}
      <p className="text-2xl font-bold">{title}</p>
      {subTitle != null && <p className="text-base text-muted-foreground">{subTitle}</p>}
      {action != null && (
        <Link href={action.route}>
          <Button iconLeft={action.icon} variant="primary">
            {action.label}
          </Button>
        </Link>
      )}
    </div>
  );
}
