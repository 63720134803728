import type { IOrganizationId } from "@archetype/ids";
import { builderTrpc, organizationGlobalContext } from "@archetype/trpc-react";
import { NonIdealState } from "@archetype/ui";
import { useClerk } from "@clerk/nextjs";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import { useEffect } from "react";

import { AppLoader } from "../AppLoader";
import { AuthenticationContext } from "./AuthenticationContext";
import { getPageAuthenticationType } from "./pageDefinitions";
import type { IAuthenticatedPageType, IClerkUser } from "./types";
import { getRouterOrganizationId } from "./useRouterOrganizationId";

export const AuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const orgId = getRouterOrganizationId(router);

  // Not set as an effect as we don't need to wait for another render loop
  if (organizationGlobalContext.getOrganizationId() !== orgId) {
    organizationGlobalContext.setOrganizationId(orgId);
  }

  const pageType = getPageAuthenticationType(router);

  // Do not do anything on public pages
  if (pageType.type === "public") {
    return (
      <AuthenticationContext.Provider value={{ context: { type: "public" }, pageType }}>
        {children}
      </AuthenticationContext.Provider>
    );
  }

  return (
    <RouteWithAuthentication orgId={orgId} pageType={pageType}>
      {children}
    </RouteWithAuthentication>
  );
};

const RouteWithAuthentication: React.FC<
  PropsWithChildren<{ orgId?: IOrganizationId; pageType: IAuthenticatedPageType }>
> = ({ children, orgId, pageType }) => {
  const router = useRouter();
  // Ensures clerk is fully loaded
  const { user, loaded } = useClerk();

  if (!loaded) {
    return <AppLoader />;
  }

  if (user == null) {
    // Public pages are allowed to render
    if (pageType.scope === "public") {
      return (
        <AuthenticationContext.Provider
          value={{ context: { type: "authenticated", scope: "signedOut", meta: undefined }, pageType }}
        >
          {children}
        </AuthenticationContext.Provider>
      );
    }

    // Redirect for non-public pages
    void router.push({
      pathname: "/sign-in/[[...index]]",
      query: { returnUrl: router.asPath },
    });

    return <AppLoader />;
  }

  if (orgId != null) {
    return (
      <RouteWithOrganizationRegistration orgId={orgId} pageType={pageType} user={user}>
        {children}
      </RouteWithOrganizationRegistration>
    );
  }

  return (
    <AuthenticationContext.Provider
      value={{ context: { type: "authenticated", scope: "signedIn", meta: { clerkUser: user } }, pageType }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

/**
 * Handle sign-in redirects and registration of the user with the org
 *
 * Also handles setting the active org before allowing content to be rendered
 */
const RouteWithOrganizationRegistration: React.FC<
  PropsWithChildren<{ orgId: IOrganizationId; pageType: IAuthenticatedPageType; user: IClerkUser }>
> = ({ children, orgId, pageType, user }) => {
  const {
    mutateAsync: registerToOrganization,
    data: registrationResult,
    isPending,
    isError,
  } = builderTrpc.users.maybeRegisterToOrganization.useMutation();

  useEffect(() => {
    if (isPending || isError || registrationResult != null) {
      return;
    }

    const registerUser = async (): Promise<void> => {
      await registerToOrganization({ organizationId: orgId });
    };

    void registerUser();
  }, [registerToOrganization, orgId, isPending, isError, registrationResult]);

  if (isError) {
    return (
      <div className="flex h-dvh w-full items-center justify-center">
        <NonIdealState
          backRoute={{ pathname: "/select-organization", query: {} }}
          className="mx-2 max-w-xl"
          message="Error loading workspace"
          variant="error"
        />
      </div>
    );
  }

  if (isPending || registrationResult == null) {
    return <AppLoader />;
  }

  if (pageType.scope === "signedInOrganization" && !registrationResult.isRegistered) {
    return (
      <div className="flex h-dvh w-full items-center justify-center">
        <NonIdealState
          backRoute={{ pathname: "/select-organization", query: {} }}
          className="mx-2 max-w-xl"
          message="User is not a member of this organization"
          variant="error"
        />
      </div>
    );
  }

  return (
    <AuthenticationContext.Provider
      value={{
        context: {
          type: "authenticated",
          scope: "signedInOrganization",
          meta: {
            clerkUser: user,
            organizationId: registrationResult.organization.id,
            clerkOrganizationId: registrationResult.organization.clerkOrganizationId,
          },
        },
        pageType,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
