import { forEach } from "./forEach";

/**
 * Takes in a record of records and returns a merged flattened single record
 *
 * @param obj The source object
 * @returns A new object with the groups
 */
// mapKey: (value: Value, key: InKey) => OutKeys | undefined,
export function mergeRecordsRecord<const RootKeys extends string, const InKeys extends string, const InValue>(
  obj: Record<RootKeys, Record<InKeys, InValue>>,
): Record<InKeys, InValue> {
  const out = {} as Record<InKeys, InValue>;

  forEach(obj, (innerRecord: Record<InKeys, InValue>) => {
    forEach(innerRecord, (value, key) => {
      out[key] = value;
    });
  });

  return out;
}
