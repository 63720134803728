import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { TextFormatType } from "lexical";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useCallback, useEffect, useState } from "react";

import { Icon } from "../../../atoms/icon";

interface IToolbarButtonProps {
  active?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

function ToolbarButton({ active = false, children, onClick: handleClick }: IToolbarButtonProps): React.JSX.Element {
  return (
    <button
      className={`rounded-sm p-1 text-xs hover:bg-muted hover:text-muted-foreground ${
        active ? "bg-muted text-muted-foreground" : "text-muted-foreground"
      }`}
      type="button"
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

export function BasicToolbarPlugin(): React.JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);

  const formatText = useCallback(
    (formatType: TextFormatType) => {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
    },
    [editor],
  );

  const handleBoldClick = useCallback(() => {
    formatText("bold");
  }, [formatText]);

  const handleItalicClick = useCallback(() => {
    formatText("italic");
  }, [formatText]);

  const handleOrderedListClick = useCallback(() => {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  }, [editor]);

  const handleUnorderedListClick = useCallback(() => {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  }, [editor]);

  const handleLinkClick = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        // Update text format
        setIsBold(selection.hasFormat("bold"));
        setIsItalic(selection.hasFormat("italic"));

        // Update link format
        const nodes = selection.getNodes();
        const node = nodes[0];
        const parent = node?.getParent();

        setIsLink(parent ? $isLinkNode(parent) : false);
      });
    });
  }, [editor]);

  return (
    <div className="flex flex-wrap gap-1 border-b border-border p-1">
      <ToolbarButton active={isBold} onClick={handleBoldClick}>
        <Icon name="bold" />
      </ToolbarButton>
      <ToolbarButton active={isItalic} onClick={handleItalicClick}>
        <Icon name="italic" />
      </ToolbarButton>
      <div className="h-6 w-px bg-border" />
      <ToolbarButton onClick={handleOrderedListClick}>
        <Icon name="list-ordered" />
      </ToolbarButton>
      <ToolbarButton onClick={handleUnorderedListClick}>
        <Icon name="list" />
      </ToolbarButton>
      <div className="h-6 w-px bg-border" />
      <ToolbarButton active={isLink} onClick={handleLinkClick}>
        <Icon name="link" />
      </ToolbarButton>
    </div>
  );
}
