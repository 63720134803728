import type { ObjectIterator } from "lodash";
// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { map as _map } from "lodash";

/**
 * Creates an array of values by running each property of `obj` through `iteratee`.
 *
 * @param obj The object to iterate over
 * @param iteratee The function invoked per iteration
 * @returns An array of the results of running `iteratee` for each property
 */

export function map<const Key extends string, const Value, R>(
  obj: Record<Key, Value> | undefined,
  iteratee: (value: Value, key: Key, obj: Record<Key, Value>) => R,
): R[] {
  const typedIterator = iteratee as ObjectIterator<Record<Key, Value>, R>;

  return _map(obj, typedIterator);
}
