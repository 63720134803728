import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";

export type IInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  iconLeft?: IIconNames;
  containerClassName?: string;
  small?: boolean;
};

const inputVariants = cva(
  "flex w-full rounded-lg border border-border bg-input px-3 py-2 text-ink ring-offset-paper file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:tracking-wide placeholder:text-placeholder focus-visible:border-ring focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      size: {
        default: "h-11 min-h-11 text-lg",
        small: "h-7 min-h-7 text-base",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  ({ className, containerClassName, type, iconLeft, small, ...props }, ref) => {
    const iconClass = "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-placeholder";

    return (
      <div
        className={cn(
          "relative flex grow items-center focus-within:border-ring focus-within:ring-ring",
          containerClassName,
        )}
      >
        {iconLeft != null ? (
          <div className={iconClass}>
            <Icon className="size-4" name={iconLeft} />
          </div>
        ) : null}
        <input
          ref={ref}
          className={cn(
            inputVariants({ size: small === true ? "small" : "default" }),
            iconLeft != null && "pl-10",
            className,
          )}
          type={type}
          {...props}
        />
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input, inputVariants };
