"use client";

import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "d921c200-5294-4539-8d5b-182868a9a841", //todo: move to const
  clientToken: "pub158ef6e9de3255dc8032da31ecb40d98", //todo: move to env var
  site: "datadoghq.eu",
  service: "builder",
  proxy: Boolean(process.env.NEXT_PUBLIC_DD_TUNNEL_REQUESTS) ? "/api/dd-monitoring" : undefined,
  env: process.env.NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [{ match: /\/api\/trpc/, propagatorTypes: ["tracecontext"] }],
});

export default function DatadogInit(): React.ReactNode {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return undefined;
}
