import React from "react";

import { cn } from "../../lib/utils";

interface IFormattedUrlProps {
  url: string;
  className?: string;
}

export const FormattedUrl: React.FC<IFormattedUrlProps> = ({ url, className }) => {
  const cleanUrl = url.replace(/^https?:\/\/(www\.)?/, "");
  const [domain, ...pathParts] = cleanUrl.split("/");
  const path = pathParts.join("/");

  if (path === "") {
    return (
      <div className={cn("flex max-w-full items-center overflow-hidden", className)}>
        <span>{domain}</span>
      </div>
    );
  }

  if (path.length <= 11) {
    return (
      <div className={cn("flex max-w-full items-center overflow-hidden", className)}>
        <span>{domain}/</span>
        <span>{path}</span>
      </div>
    );
  }

  const firstThree = path.slice(0, 3);
  const lastSix = path.slice(-6);

  return (
    <div className={cn("flex max-w-full items-center overflow-hidden", className)}>
      <span>{domain}/</span>
      <span>{firstThree}</span>
      <span>...</span>
      <span className="shrink-0">{lastSix}</span>
    </div>
  );
};
