import Link from "next/link";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import React from "react";

import { createFileLogger } from "../logger";
import type { IRoute } from "./api";

interface IEnhancedRouter<P extends object> {
  LinkRenderer: React.FC<P & { className?: string }>;
  navigate: (props: P, router?: NextRouter) => Promise<boolean>;
  getHref: (props: P) => IRoute;
}

const logger = createFileLogger("enhancedLinkRenderer.tsx");

export function enhancedLinkRenderer<P extends object>(hrefGenerator: (props: P) => IRoute): IEnhancedRouter<P> {
  const LinkRenderer: React.FC<PropsWithChildren<P & { className?: string }>> = ({ children, className, ...props }) => (
    <Link className={className} href={hrefGenerator(props as P)} passHref={true}>
      {children}
    </Link>
  );

  const navigate = async (props: P, router?: NextRouter): Promise<boolean> => {
    const href = hrefGenerator(props);

    if (router) {
      return router.push(href);
    } else if (typeof window !== "undefined") {
      const { default: Router } = await import("next/router");

      return Router.push(href);
    }
    logger.warn({}, "Navigation attempted on server side");

    return false;
  };

  return {
    LinkRenderer,
    navigate,
    getHref: hrefGenerator,
  };
}

export function useEnhancedLinkRenderer<P extends object>(
  routeCreator: (props: P, router: NextRouter) => IRoute,
): IEnhancedRouter<P> {
  const router = useRouter();
  const hrefGenerator = (props: P): IRoute => routeCreator(props, router);

  return enhancedLinkRenderer(hrefGenerator);
}
