import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "../../atoms/tooltip";

interface ICommentTimestampProps {
  timestamp: Date;
}

const UPDATE_INTERVAL = 60000;

export const CommentTimestamp = ({ timestamp }: ICommentTimestampProps): React.JSX.Element => {
  const [, setTime] = useState(new Date());

  // Force a re-render every minute to update the relative time
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, UPDATE_INTERVAL);

    return (): void => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Tooltip>
      <TooltipTrigger>{DateTime.fromJSDate(timestamp).toRelative()}</TooltipTrigger>
      <TooltipContent>{DateTime.fromJSDate(timestamp).toLocaleString(DateTime.DATETIME_MED)}</TooltipContent>
    </Tooltip>
  );
};
