import * as React from "react";

import { cn } from "../../lib/utils";
import { Eyebrow } from "./eyebrow";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export const AutofillIndicator: React.FC<{
  className?: string;
  type: "logic" | "ai";
}> = ({ className, type }) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <Eyebrow className={cn("leading-6", className)} variant="secondary">
        {type === "ai" ? "Autofilled by AI" : "Autofilled"}
      </Eyebrow>
    </TooltipTrigger>
    <TooltipContent>
      <p>{type === "ai" ? "This field is autofilled by AI" : "This field is autofilled by a formula"}</p>
    </TooltipContent>
  </Tooltip>
);
