import { createContext, useContext } from "react";

import type { IAuthenticationContext, IPageAuthenticationType } from "./types";

interface IAuthenticationContextType {
  context: IAuthenticationContext;
  pageType: IPageAuthenticationType;
}

export const AuthenticationContext = createContext<IAuthenticationContextType | undefined>(undefined);

export const useAuthenticationContext = (): IAuthenticationContextType => {
  const context = useContext(AuthenticationContext);

  if (context == null) {
    throw new Error("useAuthenticationContext must be used within an AuthenticationProvider");
  }

  return context;
};

export const useIsSignedIn = (): boolean => {
  const { context } = useAuthenticationContext();

  return context.type === "authenticated" && context.scope !== "signedOut";
};

export const useIsSignedInOrganization = (): boolean => {
  const { context } = useAuthenticationContext();

  return context.type === "authenticated" && context.scope === "signedInOrganization";
};
