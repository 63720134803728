import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils";

const colors = {
  lilac: "bg-pastel-lilac-background",
  purple: "bg-pastel-purple-background",
  pink: "bg-pastel-pink-background",
  red: "bg-pastel-red-background",
  orange: "bg-pastel-orange-background",
  brown: "bg-pastel-brown-background",
  yellow: "bg-pastel-yellow-background",
  lime: "bg-pastel-lime-background",
  sage: "bg-pastel-sage-background",
  green: "bg-pastel-green-background",
  emerald: "bg-pastel-emerald-background",
  teal: "bg-pastel-teal-background",
  blue: "bg-pastel-blue-background",
  neutral: "bg-pastel-neutral-background",
} as const;

const ringColors = {
  lilac: "ring-pastel-lilac-background",
  purple: "ring-pastel-purple-background",
  pink: "ring-pastel-pink-background",
  red: "ring-pastel-red-background",
  orange: "ring-pastel-orange-background",
  brown: "ring-pastel-brown-background",
  yellow: "ring-pastel-yellow-background",
  lime: "ring-pastel-lime-background",
  sage: "ring-pastel-sage-background",
  green: "ring-pastel-green-background",
  emerald: "ring-pastel-emerald-background",
  teal: "ring-pastel-teal-background",
  blue: "ring-pastel-blue-background",
  neutral: "ring-pastel-neutral-background",
} as const;

export const ShapeColorVariants = cva("relative", {
  variants: {
    color: colors,
  },
  defaultVariants: {
    color: "neutral",
  },
});
export type IShapeColorVariants = keyof typeof colors;
export const ShapeColorVariantsList = Object.keys(colors) as IShapeColorVariants[];

interface IShapeColorComponentProps {
  className?: string;
  selected?: boolean;
  color: IShapeColorVariants;
}

export const ShapeColorComponent = ({ className, selected, color }: IShapeColorComponentProps): React.ReactNode => {
  return (
    <span
      className={cn(
        ShapeColorVariants({ color: color }),
        selected === true && ringColors[color],
        selected === true && "ring-2",
        className,
      )}
    />
  );
};
