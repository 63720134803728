"use client";

import posthog from "posthog-js";
import { PostHogProvider as PostHogProviderBase } from "posthog-js/react";

if (typeof window !== "undefined") {
  // checks that we are client-side
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should be present but not typed
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://us.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    loaded: (ph) => {
      if (process.env.NODE_ENV === "development") ph.debug(); // debug mode in development
    },
  });
}

export const PostHogProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return <PostHogProviderBase client={posthog}>{children}</PostHogProviderBase>;
};
