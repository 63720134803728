"use client";

import { map, mapValues } from "@archetype/utils";
import { Command as CommandPrimitive, useCommandState } from "cmdk";
import * as React from "react";
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDeepCompareEffect } from "react-use";

import { cn } from "../../lib/utils";
import { Loader } from "../molecules/loader/Loader";
import { Badge } from "./badge";
import { Command, CommandGroup, CommandItem, CommandList } from "./command";
import { Icon } from "./icon";
import { inputVariants } from "./input";
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from "./popover";

export interface IMultiSelectOption<T = string> {
  value: T;
  label: string;
  disabled?: boolean;
  /** fixed option that can't be removed. */
  fixed?: boolean;
  /** Group the options by providing key. */
  [key: string]: T | string | boolean | undefined;
}
interface IGroupOption<K extends string, T extends IMultiSelectOption<K>> {
  [key: string]: T[];
}

interface IMultiSelect<K extends string, T extends IMultiSelectOption<K>> {
  value?: T[];
  defaultOptions?: T[];
  /** manually controlled options */
  options?: T[];
  placeholder?: string;
  /** Loading component. */
  loadingIndicator?: React.ReactNode;
  /** Empty component. */
  emptyIndicator?: React.ReactNode;
  /** Debounce time for async search. Only work with `onSearch`. */
  delay?: number;
  /**
   * Only work with `onSearch` prop. Trigger search when `onFocus`.
   * For example, when user click on the input, it will trigger the search to get initial options.
   **/
  triggerSearchOnFocus?: boolean;
  onSearch?: (value: string) => void;
  onChange?: (options: T[]) => void;
  /** Limit the maximum number of selected options. */
  maxSelected?: number;
  /** When the number of selected options exceeds the limit, the onMaxSelected will be called. */
  onMaxSelected?: (maxLimit: number) => void;
  /** Hide the placeholder when there are options selected. */
  hidePlaceholderWhenSelected?: boolean;
  disabled?: boolean;
  /** Group the options base on provided key. */
  groupBy?: string;
  className?: string;
  /**
   * First item selected is a default behavior by cmdk. That is why the default is true.
   * This is a workaround solution by add a dummy item.
   *
   * @reference: https://github.com/pacocoursey/cmdk/issues/171
   */
  selectFirstItem?: boolean;
  /** Function to call when creating a new item */
  onCreateItem?: (value: string) => void;
  /** Props of `Command` */
  commandProps?: React.ComponentPropsWithoutRef<typeof Command>;
  /** Props of `CommandInput` */
  inputProps?: Omit<React.ComponentPropsWithRef<typeof CommandPrimitive.Input>, "value" | "placeholder" | "disabled">;
  /** hide the clear all button. */
  hideClearAllButton?: boolean;
  /** Custom renderer for options */
  optionRenderer?: (option: T) => React.ReactNode;
  /** Custom renderer for selected badges */
  badgeRenderer?: (
    option: T,
    props: {
      onUnselect: () => void;
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
      onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
    },
  ) => React.ReactNode;
  /** Indicates if the component is in a loading state */
  isLoading?: boolean;
  /** Indicates if the component should use the small variant */
  small?: boolean;
  ref?: React.Ref<IMultiSelectRef<T>>;
  variant?: "default" | "borderless";
}

export interface IMultiSelectRef<T = string> {
  selectedValue: T[];
  input: HTMLInputElement;
  focus: () => void;
  reset: () => void;
}

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 500);

    return (): void => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

function convertToGroupOptions<K extends string, T extends IMultiSelectOption<K>>(
  options: T[],
  groupBy?: string,
): IGroupOption<K, T> {
  if (options.length === 0) {
    return {};
  }

  if (groupBy == null) {
    return {
      "": options,
    };
  }

  const groupOption: IGroupOption<K, T> = {};

  options.forEach((option: T) => {
    const key = (option[groupBy] as string | undefined) ?? "";
    const existing = groupOption[key];

    if (existing == null) {
      groupOption[key] = [option];
    } else {
      groupOption[key] = [...existing, option];
    }
  });

  return groupOption;
}

function removeSelectedOptions<K extends string, T extends IMultiSelectOption<K>>(
  groupOption: IGroupOption<K, T>,
  picked: T[],
): IGroupOption<K, T> {
  return mapValues(groupOption, (value) => value.filter((val) => !picked.some((p) => p.value === val.value)));
}

function defaultBadgeRenderer<T>(
  option: IMultiSelectOption<T>,
  props: {
    className?: string;
    onUnselect: () => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
  },
): React.ReactNode {
  return (
    <Badge
      className={props.className}
      colorVariant={option.fixed === true ? "gray" : "white"}
      iconRight="x"
      interactive={option.fixed !== true}
      size="sm"
      onClick={props.onUnselect}
      onKeyDown={props.onKeyDown}
      onMouseDown={props.onMouseDown}
    >
      {option.label}
    </Badge>
  );
}

interface ICommandState {
  filtered: {
    count: number;
  };
}

const CommandEmpty = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CommandPrimitive.Empty>>(
  ({ className, ...props }, forwardedRef) => {
    const render = useCommandState((state: ICommandState) => state.filtered.count === 0) as boolean;

    if (!render) return null;

    return (
      <div
        ref={forwardedRef}
        className={cn("py-6 text-center text-base", className)}
        cmdk-empty=""
        role="presentation"
        {...props}
      />
    );
  },
);

CommandEmpty.displayName = "CommandEmpty";

function MultiSelect<K extends string, T extends IMultiSelectOption<K> = IMultiSelectOption<K>>({
  value,
  onChange,
  placeholder,
  defaultOptions: arrayDefaultOptions = [],
  options: arrayOptions,
  delay,
  onSearch,
  loadingIndicator,
  emptyIndicator,
  maxSelected = Number.MAX_SAFE_INTEGER,
  onMaxSelected,
  hidePlaceholderWhenSelected,
  disabled,
  groupBy,
  className,
  selectFirstItem = true,
  onCreateItem,
  triggerSearchOnFocus = false,
  commandProps,
  inputProps,
  hideClearAllButton = false,
  optionRenderer,
  badgeRenderer,
  isLoading = false,
  small = false,
  variant = "default",
  ref,
}: IMultiSelect<K, T>): React.ReactNode {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [onScrollbar, setOnScrollbar] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState<T[]>(value || []);
  const [options, setOptions] = useState<IGroupOption<K, T>>(convertToGroupOptions(arrayDefaultOptions, groupBy));
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchTerm = useDebounce(inputValue, delay ?? 500);

  useImperativeHandle(
    ref,
    () => ({
      selectedValue: [...selected],
      // Disable non null assertion because we cant make this conditional
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should be safe
      input: inputRef.current!,
      focus: (): void => inputRef.current?.focus(),
      reset: (): void => {
        setSelected([]);
      },
    }),
    [selected],
  );

  useDeepCompareEffect(() => {
    setOptions(convertToGroupOptions(arrayOptions ?? [], groupBy));
  }, [arrayOptions, groupBy]);

  const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
      inputRef.current.blur();
    }
  };

  const handleDeselect = useCallback(
    (option: T) => {
      const newOptions = selected.filter((s) => s !== option);

      setSelected(newOptions);
      onChange?.(newOptions);
    },
    [onChange, selected],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;

      if (input) {
        if (e.key === "Delete" || e.key === "Backspace") {
          if (input.value === "" && selected.length > 0) {
            const lastSelectOption = selected[selected.length - 1];

            // If last item is fixed, we should not remove it.
            if (lastSelectOption?.fixed !== true && lastSelectOption != null) {
              handleDeselect(lastSelectOption);
            }
          }
        }
        // This is not a default behavior of the <input /> field
        if (e.key === "Escape") {
          input.blur();
        }
      }
    },
    [handleDeselect, selected],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchend", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    }

    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    /** If `onSearch` is provided, do not trigger options updated. */
    if (arrayOptions == null || onSearch) {
      return;
    }

    const newOption = convertToGroupOptions(arrayOptions, groupBy);

    if (JSON.stringify(newOption) !== JSON.stringify(options)) {
      setOptions(newOption);
    }
  }, [arrayDefaultOptions, arrayOptions, groupBy, onSearch, options]);

  useEffect(() => {
    if (!onSearch || !open) return;

    if (triggerSearchOnFocus) {
      onSearch(debouncedSearchTerm);
    }

    if (debouncedSearchTerm !== "") {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, groupBy, open, triggerSearchOnFocus, onSearch]);

  const creatable = onCreateItem != null;

  const EmptyItem = useCallback(() => {
    if (emptyIndicator !== true) return undefined;

    // For async search that showing emptyIndicator
    if (onSearch && !creatable && Object.keys(options).length === 0) {
      return (
        <CommandItem disabled value="-">
          {emptyIndicator}
        </CommandItem>
      );
    }

    return <CommandEmpty>{emptyIndicator}</CommandEmpty>;
  }, [creatable, emptyIndicator, onSearch, options]);

  const selectables = useMemo<IGroupOption<K, T>>(() => removeSelectedOptions(options, selected), [options, selected]);

  /** Avoid Creatable Selector freezing or lagging when paste a long string. */
  const commandFilter = useCallback(() => {
    if (commandProps?.filter) {
      return commandProps.filter;
    }

    if (creatable) {
      return (v: string, search: string): number => {
        return v.toLowerCase().includes(search.toLowerCase()) ? 1 : -1;
      };
    }

    // Using default filter in `cmdk`. We don't have to provide it.
    return undefined;
  }, [creatable, commandProps?.filter]);

  const handleCommandKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      handleKeyDown(e);
      commandProps?.onKeyDown?.(e);
    },
    [commandProps, handleKeyDown],
  );

  const handleInputValueChange = useCallback(
    (v: string) => {
      setInputValue(v);
      inputProps?.onValueChange?.(v);
    },
    [inputProps],
  );

  const handleWrapperClick = useCallback(() => {
    if (disabled === true) return;
    inputRef.current?.focus();
  }, [disabled]);

  const handleBadgeKeyDown = useCallback(
    (opt: T) =>
      (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === "Delete" || e.key === "Backspace") {
          e.preventDefault();
          e.stopPropagation();
          handleDeselect(opt);
        }
      },
    [handleDeselect],
  );

  const handleBadgeMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleBadgeClick = useCallback(
    (opt: T) => (): void => {
      handleDeselect(opt);
    },
    [handleDeselect],
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!onScrollbar) {
        setOpen(false);
      }
      setInputValue("");
      inputProps?.onBlur?.(event);
    },
    [onScrollbar, inputProps],
  );

  const handleInputFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setOpen(true);
      if (triggerSearchOnFocus) {
        onSearch?.(debouncedSearchTerm);
      }
      inputProps?.onFocus?.(event);
    },
    [onSearch, debouncedSearchTerm, triggerSearchOnFocus, inputProps],
  );

  const handleClearAll = useCallback(() => {
    setSelected(selected.filter((s) => s.fixed ?? false));
    onChange?.(selected.filter((s) => s.fixed ?? false));
  }, [selected, onChange]);

  const handleListMouseLeave = useCallback(() => {
    setOnScrollbar(false);
  }, []);

  const handleListMouseEnter = useCallback(() => {
    setOnScrollbar(true);
  }, []);

  const handleListMouseUp = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleItemMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // Necessary so blur doesn't trigger when opening the popover
  const handlePopoverOpenAutoFocus = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const handleItemSelect = useCallback(
    (option: T) => (): void => {
      if (selected.length >= maxSelected) {
        onMaxSelected?.(selected.length);

        return;
      }
      setInputValue("");
      const newOptions = [...selected, option];

      setSelected(newOptions);
      onChange?.(newOptions);
    },
    [onChange, maxSelected, onMaxSelected, selected],
  );

  const handleCreateItem = useCallback(async (): Promise<void> => {
    onCreateItem?.(inputValue);
    setOpen(false);
  }, [inputValue, onCreateItem]);

  const CreatableItem = (): React.ReactNode => {
    if (!creatable) return undefined;

    return (
      <CommandGroup>
        <CommandItem
          className="cursor-pointer"
          value="create-new"
          onMouseDown={handleItemMouseDown}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- functional
          onSelect={handleCreateItem}
        >
          <span className="flex items-center gap-x-1">
            <Icon name="plus" />
            <span>Create new</span>
          </span>
        </CommandItem>
      </CommandGroup>
    );
  };

  return (
    <Popover open={open}>
      <Command
        ref={dropdownRef}
        {...commandProps}
        className={cn("h-auto overflow-visible bg-transparent", commandProps?.className)}
        filter={commandFilter()}
        shouldFilter={commandProps?.shouldFilter !== undefined ? commandProps.shouldFilter : !onSearch} // When onSearch is provided, we don't want to filter the options. You can still override it.
        onKeyDown={handleCommandKeyDown}
      >
        <PopoverTrigger asChild>
          <div
            className={cn(
              inputVariants({ size: small ? "small" : "default" }),
              "h-fit min-h-11", // I think we can always use min-h-11 because the badges are always there
              variant === "borderless" && "border-0",
              {
                "cursor-text": disabled !== true && selected.length > 0,
                "bg-muted-background": disabled === true,
              },
              className,
            )}
            onClick={handleWrapperClick}
          >
            <div className="relative flex grow flex-wrap gap-1">
              {selected.map((option) => {
                const handleBadgeClickForOption = handleBadgeClick(option);
                const handleBadgeKeyDownForOption = handleBadgeKeyDown(option);
                const props = {
                  onUnselect: handleBadgeClickForOption,
                  onKeyDown: handleBadgeKeyDownForOption,
                  onMouseDown: handleBadgeMouseDown,
                };

                return badgeRenderer ? (
                  <React.Fragment key={option.value}>{badgeRenderer(option, props)}</React.Fragment>
                ) : (
                  defaultBadgeRenderer(option, props)
                );
              })}
              {/* Avoid having the "Search" Icon */}
              <CommandPrimitive.Input
                {...inputProps}
                ref={inputRef}
                className={cn(
                  "w-full flex-1 bg-transparent outline-none placeholder:tracking-wide placeholder:text-placeholder",
                  inputValue === "" && "!text-muted-foreground",
                  selected.length > 0 && "pl-1",
                  small ? "text-base" : "text-lg",
                  inputProps?.className,
                )}
                disabled={disabled}
                placeholder={hidePlaceholderWhenSelected === true && selected.length > 0 ? "" : placeholder}
                value={inputValue}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                onValueChange={handleInputValueChange}
              />
              <button
                className={cn(
                  "relative top-px mr-1 p-0 text-muted-foreground",
                  small ? "bottom-1" : "bottom-1",
                  (hideClearAllButton ||
                    disabled === true ||
                    selected.length === 0 ||
                    selected.filter((s) => s.fixed ?? false).length === selected.length) &&
                    "hidden",
                )}
                type="button"
                onClick={handleClearAll}
              >
                <Icon className={cn(small && "size-3")} name="x" />
              </button>
            </div>
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            autoFocus={false}
            className="max-h-[300px] w-full min-w-72 p-0 shadow outline-none animate-in"
            style={{ width: "var(--radix-popover-trigger-width)" }}
            onOpenAutoFocus={handlePopoverOpenAutoFocus}
          >
            <CommandList
              className={cn("w-full", small ? "text-base" : "text-lg")}
              onMouseEnter={handleListMouseEnter}
              onMouseLeave={handleListMouseLeave}
              onMouseUp={handleListMouseUp}
            >
              {isLoading ? (
                <div className="flex items-center justify-center p-4">
                  {loadingIndicator != null ? loadingIndicator : <Loader />}
                </div>
              ) : (
                <>
                  {EmptyItem()}
                  {CreatableItem()}
                  {!selectFirstItem && <CommandItem className="hidden" value="-" />}
                  {map(selectables, (dropdowns, key) => (
                    <CommandGroup key={key} className="h-full overflow-auto" heading={key}>
                      <>
                        {dropdowns.map((option) => {
                          return (
                            <CommandItem
                              key={option.value}
                              className={cn(
                                "cursor-pointer",
                                option.disabled === true && "cursor-default text-muted-foreground",
                              )}
                              disabled={option.disabled}
                              value={option.value}
                              onMouseDown={handleItemMouseDown}
                              onSelect={handleItemSelect(option)}
                            >
                              {optionRenderer ? optionRenderer(option) : option.label}
                            </CommandItem>
                          );
                        })}
                      </>
                    </CommandGroup>
                  ))}
                </>
              )}
            </CommandList>
          </PopoverContent>
        </PopoverPortal>
      </Command>
    </Popover>
  );
}

MultiSelect.displayName = "MultiSelect";
export { MultiSelect };
