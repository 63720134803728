import type { NextRouter } from "next/router";
import type { Route } from "nextjs-routes";

import type { IPageAuthenticationType } from "./types";

export const getPageAuthenticationType = (router: NextRouter): IPageAuthenticationType => {
  if (isPublicPage(router)) {
    return { type: "public" };
  }

  if (isPublicAuthenticatedPage(router)) {
    return { type: "auth", scope: "public" };
  }

  if (isSignedInAuthenticatedPage(router)) {
    return { type: "auth", scope: "signedIn" };
  }

  // Most restrictive by default
  return { type: "auth", scope: "signedInOrganization" };
};

const isPublicPage = (router: NextRouter): boolean => {
  const filteredRoutes: Route["pathname"][] = ["/sign-in/[[...index]]", "/sign-up/[[...index]]", "/session"];

  return filteredRoutes.includes(router.pathname);
};

const isPublicAuthenticatedPage = (router: NextRouter): boolean => {
  // Match all workspace pages
  return router.pathname.startsWith("/[orgId]/workspace");
};

const isSignedInAuthenticatedPage = (router: NextRouter): boolean => {
  return router.pathname === "/register/[clerkOrgId]";
};
