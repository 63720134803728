import type { TextMatchTransformer } from "@lexical/markdown";
import {
  BOLD_STAR,
  BOLD_UNDERSCORE,
  CODE,
  HEADING,
  INLINE_CODE,
  ITALIC_UNDERSCORE,
  LINK,
  ORDERED_LIST,
  QUOTE,
  STRIKETHROUGH,
  UNORDERED_LIST,
} from "@lexical/markdown";
import type { EditorState, TextNode } from "lexical";

export * from "./defaultConfig";
export * from "./MarkdownEditor";
export * from "./nodes/MentionNode";
export * from "./plugins/AutoFocusPlugin";
export * from "./plugins/AutoLinkPlugin";
export * from "./plugins/DefaultPlugins";
export * from "./plugins/HistoryPlugin";
export * from "./plugins/LinkPlugin";
export * from "./plugins/ListPlugin";
export * from "./plugins/MarkdownShortcutPlugin";
export * from "./plugins/MentionsPlugin";

export type { TextMatchTransformer };
export type { EditorState, TextNode };

export const DEFAULT_TRANSFORMERS = [
  UNORDERED_LIST,
  ORDERED_LIST,
  CODE,
  HEADING,
  QUOTE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_UNDERSCORE,
  STRIKETHROUGH,
  LINK,
  INLINE_CODE,
];
