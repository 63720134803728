import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import type { ClassValue } from "clsx";
import * as React from "react";

import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Spinner } from "./spinner";

const badgeVariants = cva(
  "inline-flex items-center justify-center gap-x-1 rounded-lg border border-secondary-background px-2.5 py-0 font-normal tracking-wide text-ink transition-colors focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      colorVariant: {
        lilac: "bg-pastel-lilac-background",
        purple: "bg-pastel-purple-background",
        pink: "bg-pastel-pink-background",
        red: "bg-pastel-red-background",
        brown: "bg-pastel-brown-background",
        orange: "bg-pastel-orange-background",
        yellow: "bg-pastel-yellow-background",
        lime: "bg-pastel-lime-background",
        sage: "bg-pastel-sage-background",
        green: "bg-pastel-green-background",
        emerald: "bg-pastel-emerald-background",
        teal: "bg-pastel-teal-background",
        blue: "bg-pastel-blue-background",
        gray: "bg-pastel-neutral-background",
        primary: "bg-primary-background text-white",
        transparent: "bg-transparent",
        white: "bg-paper",
        dark: "bg-ink text-paper",
      },
      size: {
        sm: "h-6 px-2 text-sm",
        md: "h-8 px-2.5 text-base",
      },
    },
    defaultVariants: {
      colorVariant: "gray",
      size: "sm",
    },
  },
);

export type IBadgeColorVariant = VariantProps<typeof badgeVariants>["colorVariant"];

export interface IBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  colorVariant?: IBadgeColorVariant;
  variant?: "solid" | "ghost";
  size?: "sm" | "md";
  asChild?: boolean;
  iconLeft?: IIconNames;
  iconRight?: IIconNames;
  isLoading?: boolean;
  interactive?: boolean;
}

function getSquareStyles({
  children,
  iconLeft,
  iconRight,
  size,
}: {
  children: IBadgeProps["children"];
  iconLeft: IBadgeProps["iconLeft"];
  iconRight: IBadgeProps["iconRight"];
  size: IBadgeProps["size"];
}): ClassValue {
  if (children == null && (iconLeft != null || iconRight != null)) {
    return size === "sm" ? "size-6 px-1" : "size-8 px-1";
  }

  return "";
}

const Badge = React.forwardRef<HTMLDivElement, IBadgeProps>(
  (
    { className, colorVariant, size, asChild = false, children, iconLeft, iconRight, isLoading, interactive, ...props },
    ref,
  ) => {
    const BadgeComponent = asChild ? Slot : "div";

    const iconClass = size === "sm" ? "h-3 w-3" : "h-4 w-4";
    const squareStyles = getSquareStyles({ children, iconLeft, iconRight, size });

    const content =
      isLoading === true ? (
        <Spinner />
      ) : (
        <div className={cn("flex min-w-0 items-center gap-x-1")}>
          {iconLeft != null ? <Icon className={cn(iconClass, "shrink-0")} name={iconLeft} /> : null}
          {children != null && <span className="min-w-0 truncate leading-[normal]">{children}</span>}
          {iconRight != null ? <Icon className={cn(iconClass, "shrink-0")} name={iconRight} /> : null}
        </div>
      );

    return (
      <BadgeComponent
        ref={ref}
        className={cn(
          badgeVariants({ colorVariant, size, className }),
          squareStyles,
          "min-w-0 max-w-full",
          interactive === true && "cursor-pointer hover:brightness-95",
        )}
        {...props}
      >
        {content}
      </BadgeComponent>
    );
  },
);

Badge.displayName = "Badge";

export { Badge, badgeVariants };
