/**
 * Maps both keys and values of an object using an iteratee function.
 *
 * @param obj The source object
 * @param iteratee Function that returns a new key-value pair for each property
 * @returns A new object with transformed keys and values
 */
export function keyByAndMapValues<const OutKeys extends string, const InValue, const OutValue>(
  arr: Array<InValue>,
  /**
   * If returning undefined, the key-vale pair will not be added to the output object.
   */
  iteratee: (
    value: InValue,
    index: number,
  ) =>
    | {
        key: OutKeys;
        value: OutValue;
      }
    | undefined,
): Record<OutKeys, OutValue> {
  const out = {} as Record<OutKeys, OutValue>;

  arr.forEach((value, idx) => {
    const mappedVals = iteratee(value, idx);

    if (mappedVals != null) {
      out[mappedVals.key] = mappedVals.value;
    }
  });

  return out;
}
