import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

const dotNotificationVariants = cva("rounded-full", {
  variants: {
    targetVariant: {
      // Adding the button variants here to ensure an outline for dark looking buttons, and danger default on danger
      primary: "bg-primary outline outline-paper",
      secondary: "bg-primary outline outline-paper",
      ghost: "bg-primary outline outline-paper",
      link: "bg-primary outline outline-paper",
    },
    notificationKind: {
      default: "",
      forceDanger: "bg-red-500",
    },
  },
  defaultVariants: {
    notificationKind: "default",
    targetVariant: "secondary",
  },
});

const DotNotification: React.FC<{ className?: string } & VariantProps<typeof dotNotificationVariants>> = ({
  className,
  notificationKind,
  targetVariant,
}) => <div className={cn(dotNotificationVariants({ notificationKind, targetVariant }), className)} />;

export default DotNotification;
DotNotification.displayName = "DotNotification";

export { DotNotification, dotNotificationVariants };
