import { Loader } from "@archetype/ui";

/**
 * To be used when the root pages are loading a workspace
 */
export const AppLoader: React.FC = () => {
  return (
    <div className="flex h-dvh w-full items-center justify-center">
      <Loader text="Loading your workspace..." variant="pyramid" />
    </div>
  );
};
