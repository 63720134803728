import { useCallback, useEffect, useMemo, useState } from "react";

import { cn } from "../../lib/utils";
import { Icon } from "./icon";
import { Spinner } from "./spinner";

interface ICommentToast {
  className?: string;
  status: "loading" | "success" | "error";
  onClick?: () => void;
}

export const CommentToast: React.FC<ICommentToast> = ({ status, className, onClick }) => {
  let text = "error posting comment";

  if (status === "loading") {
    text = "posting comment";
  } else if (status === "success") {
    text = "comment posted";
  }

  const [showSuccessCheck, setShowSuccessCheck] = useState(false);

  useEffect(() => {
    if (status === "success") {
      setShowSuccessCheck(true);
      const timeout = setTimeout(() => {
        setShowSuccessCheck(false);
      }, 1000);

      return (): void => {
        clearTimeout(timeout);
      };
    }
  }, [status]);

  const icon = useMemo(() => {
    let iconBg = "bg-error";

    if (status === "loading") {
      iconBg = "bg-primary";
    } else if (status === "success") {
      iconBg = showSuccessCheck ? "bg-success" : "bg-paper";
    }

    const iconColor = status === "success" && !showSuccessCheck ? "text-primary" : "text-white";

    let inner = null;

    if (status === "loading") {
      inner = <Spinner className="size-3" />;
    } else if (status === "success") {
      inner = showSuccessCheck ? (
        <Icon className="size-3" name="check" strokeWidth={2} />
      ) : (
        <Icon className="size-3" name="arrow-down" strokeWidth={2} />
      );
    } else {
      inner = <Icon className="size-3" name="x" strokeWidth={2} />;
    }

    return (
      <div
        className={cn(
          "flex size-4 items-center justify-center rounded-full text-white transition-all",
          iconColor,
          iconBg,
        )}
      >
        {inner}
      </div>
    );
  }, [status, showSuccessCheck]);

  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <div
      className={cn("flex flex-row gap-2 rounded-full bg-paper py-1 pl-1 pr-3 text-sm shadow-uniform-sm", className)}
      onClick={handleClick}
    >
      {icon}
      {text}
    </div>
  );
};
