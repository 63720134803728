import * as React from "react";

import { cn } from "../../lib/utils";
import { Eyebrow } from "./eyebrow";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export const LiveFieldIndicator: React.FC<{
  className?: string;
  type: "live" | "ai" | "lookup";
}> = ({ className, type }) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <Eyebrow className={cn("leading-6", className)} variant="secondary">
        {type}
      </Eyebrow>
    </TooltipTrigger>
    <TooltipContent>
      <p>
        {((): string => {
          if (type === "ai") return "Computes automatically using AI";
          if (type === "lookup") return "Computes automatically using lookup";

          return "Computes automatically";
        })()}
      </p>
    </TooltipContent>
  </Tooltip>
);
