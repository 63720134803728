import * as React from "react";

import { cn } from "../../lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { Badge, type IBadgeProps } from "./badge";
import { Icon } from "./icon";

export interface IAvatarBadgeProps extends Omit<IBadgeProps, "iconLeft" | "size" | "colorVariant" | "variant"> {
  /**
   * The URL of the avatar image
   */
  avatarSrc: string | undefined;
  /**
   * Fallback text to show when image fails to load or isn't provided
   * Usually initials of the user/entity
   */
  avatarFallback: string;
  /**
   * The variant of the badge -- text only will render no border and no bg
   */
  variant?: "text" | "badge";
}

const AvatarBadge = React.forwardRef<HTMLDivElement, IAvatarBadgeProps>(
  ({ avatarFallback, avatarSrc, children, className, iconRight, variant = "badge", ...props }, ref) => {
    const hasValidSrc = avatarSrc != null && avatarSrc !== "";

    return (
      <Badge
        ref={ref}
        className={cn(
          "h-7 rounded-full p-0",
          className,
          props.isLoading === true && "size-7",
          variant === "text" && "border-transparent",
        )}
        colorVariant="transparent"
        variant="ghost"
        {...props}
      >
        {props.isLoading !== true && (
          <div className={cn("flex items-center justify-center gap-x-1.5 p-1.5 pl-1 pr-2")}>
            <Avatar className={cn("size-5 rounded-full")}>
              {hasValidSrc ? <AvatarImage alt={avatarFallback} src={avatarSrc} /> : null}
              <AvatarFallback>{avatarFallback}</AvatarFallback>
            </Avatar>
            {children}
            {iconRight != null ? <Icon className="size-3" name={iconRight} /> : null}
          </div>
        )}
      </Badge>
    );
  },
);

AvatarBadge.displayName = "AvatarBadge";

export { AvatarBadge };
