import { type IOrganizationId, OrganizationId } from "@archetype/ids";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";

export const getRouterOrganizationId = (router: NextRouter): IOrganizationId | undefined => {
  const orgIdQueryParam = router.query.orgId;
  const orgId = Array.isArray(orgIdQueryParam) ? orgIdQueryParam.join("") : orgIdQueryParam;

  return orgId != null ? OrganizationId.parse(orgId) : undefined;
};
export const useRouterOrganizationId = (): IOrganizationId | undefined => {
  const router = useRouter();

  return getRouterOrganizationId(router);
};
